// Libs
import React from "react";
import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";

// Styles
import s from "./index.module.scss";

// Hooks
import { useSDKDeviceManageLink } from "hooks/manage-link-sdk";

// Components
import { DeviceManageLinkColumns } from "./utils";
import DeviceDetails from "components/libs/device-details";
import PageHeader from "components/libs/page-header";

function DashboardContentsDeviceManageLink() {
  const navigate = useNavigate();
  const params = useParams();

  //#region Data Hooks
  const {
    data: deviceDetails,
    onLink,
    onUnlink,
    loading,
  } = useSDKDeviceManageLink({
    tenantName: params.tenant,
    id: params.deviceId,
    timezone: "",
  });
  //#endregion

  //#region Columns
  const selectedColumns = DeviceManageLinkColumns({
    status: "selected",
    onLink: (id) => onUnlink({ firewallDestinationId: id }),
  });
  const availableColumns = DeviceManageLinkColumns({
    status: "available",
    onLink: (id) => onLink({ firewallDestinationId: id }),
  });
  //#endregion

  return (
    <>
      <PageHeader
        title="Manage Firewall Link"
        className={s.header}
        onBack={() => navigate(-1)}
      />
      {deviceDetails && (
        <>
          <section>
            <DeviceDetails deviceDetails={deviceDetails} loading={loading} />
          </section>

          <section className={s.section}>
            <h1 className={s.title}>Linked Firewall</h1>
            <Table
              columns={selectedColumns}
              dataSource={deviceDetails.selectedFirewallDestinations}
              scroll={{ x: 1200 }}
              tableLayout="auto"
              loading={loading}
            />
          </section>

          <section className={s.section}>
            <h1 className={s.title}>Firewall Available for Linking</h1>
            <Table
              columns={availableColumns}
              dataSource={deviceDetails.availableFirewallDestinations}
              scroll={{ x: 1200 }}
              tableLayout="auto"
              loading={loading}
            />
          </section>
        </>
      )}
    </>
  );
}

export default DashboardContentsDeviceManageLink;
