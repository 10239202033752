// Libs
import React, { useCallback, useState } from "react";
import { Button, Divider, Spin, Table } from "antd";
import { useParams } from "react-router-dom";

// Components
import { ControlPanelFirewallColumns } from "./utils";
import ModalAddFirewall from "components/libs/modal/add-firewall";
import PageHeader from "components/libs/page-header";

// Styles
import s from "./index.module.scss";

// Hooks
import { useSDKFirewallDestination } from "hooks/firewall-destination-sdk";

// Assets
import { firewallDataSample } from "data/control-panel";
import CompLibsCredentialsLocationTitle from "components/libs/credentials-location-title";

function DashboardControlPanelFirewall(props) {
  const params = useParams();
  const tenantName = params.tenant;

  //#region  Firewall Hooks
  const {
    data: listOfFirewall,
    loading,
    onDelete,
  } = useSDKFirewallDestination({
    tenantName: tenantName,
  });
  const firewallData = !tenantName.includes("<")
    ? listOfFirewall
    : firewallDataSample;
  //#endregion

  //#region Filter data based on location
  const firewallBasedOnType = useCallback(
    ({ type }) => {
      return (
        firewallData &&
        firewallData?.filter(
          (item) => item.credentialLocation === type.toUpperCase()
        )
      );
    },
    [firewallData]
  );
  //#endregion

  //#region  Handle Add Modal
  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = () => {
    setIsEdit(false);
    setInitialValues("");
    setShowAddModal(!showAddModal);
  };
  //#endregion

  //#region  Handle Update
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState("");

  const handleShowEditModal = (data) => {
    setIsEdit(true);
    setInitialValues(data);
    setShowAddModal(!showAddModal);
  };
  //#endregion

  //#region  Handle Columns
  const { gcpColumns, awsColumns, azureColumns } = ControlPanelFirewallColumns({
    onDelete,
    onUpdate: handleShowEditModal,
  });
  //#endregion

  return (
    <>
      <PageHeader
        title="Firewall Destination"
        className={s.header}
        extra={[
          <Button size="large" onClick={handleShowAddModal}>
            + Add Firewall
          </Button>,
        ]}
      />
      <Spin spinning={loading}>
        {firewallData && (
          <>
            <CompLibsCredentialsLocationTitle
              context="Firewall Rules"
              type="gcp"
            />
            <Table
              columns={gcpColumns}
              dataSource={firewallBasedOnType({ type: "gcp" })}
              tableLayout="auto"
              scroll={{ x: 1200 }}
              onChange={() => {
                console.log("some changes");
              }}
            />

            <Divider />

            <CompLibsCredentialsLocationTitle
              context="Firewall Rules"
              type="azure"
            />
            <Table
              columns={azureColumns}
              dataSource={firewallBasedOnType({ type: "azure" })}
              tableLayout="auto"
              scroll={{ x: 1200 }}
              onChange={() => {
                console.log("some changes");
              }}
            />

            <Divider />

            <CompLibsCredentialsLocationTitle
              context="Firewall Rules"
              type="aws"
            />
            <Table
              columns={awsColumns}
              dataSource={firewallBasedOnType({ type: "aws" })}
              tableLayout="auto"
              scroll={{ x: 1200 }}
              onChange={() => {
                console.log("some changes");
              }}
            />
          </>
        )}
      </Spin>

      {showAddModal && (
        <ModalAddFirewall
          visible={showAddModal}
          handleVisible={handleShowAddModal}
          isEdit={isEdit}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

export default DashboardControlPanelFirewall;
