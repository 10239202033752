import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { useCallback } from "react";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";
import { usePlanSuggestion } from "context/plan-suggestion";

export const useSDKDevices = ({ tenantName }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const navigate = useNavigate();
  const { setOpenModal, setTopic, setTenantName } = usePlanSuggestion();

  //#region  Get Data
  const { data, isFetching, error, refetch } = useAuthQuery({
    queryKey: ["devices"],
    queryFn: async () => {
      const res = await apiSDK.tenantApi.getDevicesByTenantName({
        tenantName: tenantName,
      });
      return res;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });
  //#endregion

  //#region  Add new device
  const { mutateAsync: onAdd, isLoading: onAddLoading } = useMutation(
    useCallback(
      async (payload) => {
        const res = await apiSDK.deviceApi.addDnsDevice({
          v1CreateDnsDeviceForm: {
            hostName: payload.hostName,
            tenantName: tenantName,
          },
        });
        return res;
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["devices"]);
        notification.success({
          message: "Successfully",
          description: "Successfully add new device",
        });

        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Device");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //#region  Unlink Device
  const { mutateAsync: onUnlink, isLoading: onUnlinkLoading } = useMutation(
    useCallback(async (data) => {
      const res = await apiSDK.deviceApi.deleteDevice({
        deviceId: data.deviceId,
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["devices"]);
        queryClient.invalidateQueries(["dashboard", tenantName]);
        notification.success({
          message: "Successfully",
          description: "successfully unlink the device",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region  Update device
  const { mutateAsync: onUpdate, isLoading: onUpdateLoading } = useMutation(
    useCallback(async ({ id, submission }) => {
      const res = await apiSDK.deviceApi.updateDnsDevice({
        deviceId: id,
        v1DnsDeviceForm: {
          hostName: submission.hostName,
          version: 1,
        },
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["devices"]);
        notification.success({
          message: "Successfully",
          description: "successfully update device",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region  Transfer device
  const { mutateAsync: onTransfer, isLoading: onTransferLoading } = useMutation(
    useCallback(async ({ id, tenantId }) => {
      const res = await apiSDK.deviceApi.transferDevice({
        deviceId: id,
        v1DeviceTransferForm: {
          tenantId: tenantId,
        },
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["devices"]);
        notification.success({
          message: "Successfully",
          description: "successfully transfer selected device",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Device");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    onAdd,
    onUnlink,
    onUpdate,
    onTransfer,
    loading:
      isFetching ||
      onUpdateLoading ||
      onAddLoading ||
      onUnlinkLoading ||
      onTransferLoading,
    error,
    refetch,
  };
};

export const useSDKDeviceHistory = ({ tenantName, id }) => {
  const { tenant } = useParams();
  const navigate = useNavigate();

  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["device-history", id],
    queryFn: async () => {
      const res = await apiSDK.tenantApi.getDeviceHistory({
        deviceId: id,
        tenantName: tenantName,
      });
      return res;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
    error,
  };
};
