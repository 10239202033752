import DashboardLayout from "components/layout/dashboard";
import MainLayout from "components/layout/main";
import AfterDeleteAccount from "pages/after-delete-account";
import MainHomepage from "pages/homepage";
import LoginRedirect from "pages/login-redirect";
import Page404 from "pages/page-404";
import Page500 from "pages/page-500";
import PaymentRedirect from "pages/payment-redirect";
import PrivacyPolicyPage from "pages/privacy-policy";
import ShowToken from "pages/show-token";
import TermsAndConditionsPage from "pages/terms-and-conditions";
import ThankYouPage from "pages/thank-you";
import { Route, Routes } from "react-router-dom";
import DashboardControlPanel from "pages/dashboard/control-panel";
import DashboardDevices from "pages/dashboard/devices";
import DashboardDevicesHistory from "pages/dashboard/devices/history";
import DashboardDeviceManageLink from "pages/dashboard/devices/manage-link";
import DashboardProfile from "pages/dashboard/profile";
import DashboardSettingsSubscription from "pages/dashboard/settings/subscription";
import DashboardSettings from "pages/dashboard/settings";
import DashboardSettingsGeneral from "pages/dashboard/settings/general";
import DashboardSettingsAddOns from "pages/dashboard/settings/add-ons";
import DashboardSettingsMembers from "pages/dashboard/settings/members";
import DashboardSettingsAdvanced from "pages/dashboard/settings/advanced";
import DashboardCredentials from "pages/dashboard/credentials";
import DashboardManageLink from "pages/dashboard/manage-link";
import DashboardFirewalls from "pages/dashboard/firewalls";
import DashboardFirewallDestinationHistory from "pages/dashboard/firewalls/history";
import DashboardFirewallsDestinationManageLink from "pages/dashboard/firewalls/manage-link";
import ErrorPage from "pages/dashboard/error-page";
import RetryAddCredentialPage from "pages/dashboard/retry-add-credential";
import TooManyRequestPage from "pages/dashboard/too-many-request";
import PagesDashboardCheckout from "pages/dashboard/checkout";
import SubscriptionRedirection from "pages/subscription-redirection";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<MainHomepage />} />
        <Route path="profile" element={<LoginRedirect />} />
        <Route path="thank-you" element={<ThankYouPage />} />
        <Route path="account-deletion" element={<AfterDeleteAccount />} />

        {/* Deprecated */}
        <Route path="payment-redirect" element={<PaymentRedirect />} />
        {/* ================= */}

        <Route
          path="subscription-redirection"
          element={<SubscriptionRedirection />}
        />

        <Route path="subscription-redirection" element={<PaymentRedirect />} />

        <Route path="show-token" element={<ShowToken />} />
        <Route path="error" element={<Page500 />} />
        <Route
          path="terms-and-conditions"
          element={<TermsAndConditionsPage />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="*" element={<Page404 />} />
      </Route>

      <Route path="/dashboard/:tenant" element={<DashboardLayout />}>
        <Route index element={<DashboardControlPanel />} />
        <Route path="devices">
          <Route index element={<DashboardDevices />} />
          <Route
            path=":deviceId/history"
            element={<DashboardDevicesHistory />}
          />
          <Route
            path=":deviceId/manage-link"
            element={<DashboardDeviceManageLink />}
          />
        </Route>
        <Route path="profile" element={<DashboardProfile />} />
        <Route path="checkout" element={<PagesDashboardCheckout />} />
        <Route path="settings" element={<DashboardSettings />}>
          <Route index element={<DashboardSettingsGeneral />} />
          <Route
            path="subscription"
            element={<DashboardSettingsSubscription />}
          />
          <Route path="add-ons" element={<DashboardSettingsAddOns />} />
          <Route path="members" element={<DashboardSettingsMembers />} />
          <Route path="advanced" element={<DashboardSettingsAdvanced />} />
        </Route>
        <Route path="credentials" element={<DashboardCredentials />} />
        <Route path="manage-link" element={<DashboardManageLink />} />
        <Route path="firewalls">
          <Route index element={<DashboardFirewalls />} />
          <Route
            path=":firewallId/history"
            element={<DashboardFirewallDestinationHistory />}
          />
          <Route
            path=":firewallId/manage-link"
            element={<DashboardFirewallsDestinationManageLink />}
          />
        </Route>
        <Route path="error-page" element={<ErrorPage />} />
        <Route
          path="retry-add-credential"
          element={<RetryAddCredentialPage />}
        />
        <Route path="too-many-request" element={<TooManyRequestPage />} />
      </Route>
    </Routes>
  );
}
