import React from "react";
import { Form, Input, Col, Button } from "antd";
import propTypes from "prop-types";

export default function ModalAddAzureCredentials(props) {
  const {
    onAdd,
    handleShow = () => {},
    initialValue = {},
    handleBack = () => {},
  } = props;

  const [form] = Form.useForm();

  //#region  Handle submit
  const handleSubmit = () => {
    form.validateFields().then(async (value) => {
      try {
        const submission = {
          azureSubscriptionId: value.azureSubscriptionId,
          azureTenantId: value.azureTenantId,
          clientId: value.clientId,
          clientSecret: value.clientSecret,
        };
        await onAdd(submission);
      } catch (error) {
        return;
      } finally {
        handleShow();
        handleBack();
        form.resetFields();
      }
    });
  };
  //#endregion

  return (
    <div style={{ marginTop: 20 }}>
      <Form form={form} layout="vertical" initialValues={initialValue}>
        <Form.Item
          name={"azureSubscriptionId"}
          label="Subscription ID"
          rules={[{ required: true, message: "please enter subscription ID" }]}
        >
          <Input placeholder="Enter subscription ID" />
        </Form.Item>
        <Form.Item
          name={"azureTenantId"}
          label="Tenant ID"
          rules={[{ required: true, message: "please enter tenant ID" }]}
        >
          <Input placeholder="Enter tenant ID" />
        </Form.Item>
        <Form.Item
          name={"clientId"}
          label="Client ID"
          rules={[{ required: true, message: "please enter client ID" }]}
        >
          <Input placeholder="Enter client ID" />
        </Form.Item>
        <Form.Item
          name={"clientSecret"}
          label="Client Secret"
          rules={[{ required: true, message: "please enter client secret" }]}
        >
          <Input.Password placeholder="Enter client secret" />
        </Form.Item>
        <Col style={{ textAlign: "right" }}>
          <Button onClick={handleSubmit}>Submit</Button>
        </Col>
      </Form>
    </div>
  );
}

ModalAddAzureCredentials.propTypes = {
  onAdd: propTypes.func,
  handleShow: propTypes.func,
  initialValue: propTypes.object,
  handleBack: propTypes.func,
};
