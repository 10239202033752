import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import integrationApi from "utils/sdk-zoho-subscriptions";

export const useZohoSubscriptions = ({ subscriptionId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { tenant } = useParams();

  //#region GET SUBSCRIPTION DETAILS
  const { data, isFetching } = useQuery({
    queryKey: ["subscription"],
    queryFn: async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1GetSubscriptionsDetails(
          {
            subscriptionId: subscriptionId,
          }
        );
      return res;
    },
    retry: async (count, error) => {
      if (count > 3) {
        return false;
      }
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!subscriptionId,
  });
  //#endregion

  //#region CREATE SUBSCRIPTION
  const { mutateAsync: onCreateSubscription, isLoading: onCreateLoading } =
    useMutation(
      useCallback(async (data) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1CreateSubscription(
            {
              subscription: {
                tenantName: data.tenantName,
                email: data.email,
                planCode: data.planCode,
                addOns: [],
                excludeTrial: data?.excludeTrial || false,
                customerId: data?.customerId || null,
              },
            }
          );
        return res;
      }, []),
      {
        onSuccess: (res) => {
          window.location.replace(res.result);
        },
        onError: (err) => {
          message.error("something wrong when redirect to payment portal");
        },
      }
    );
  //#endregion

  //#region UPDATE SUBSCRIPTION
  const { mutateAsync: onUpdateSubscription, isLoading: onUpdateLoading } =
    useMutation(
      useCallback(async (data) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1UpdateSubscription(
            {
              updateSubscription: {
                planCode: data.planCode,
                subscriptionId: data.subscriptionId,
                addOns: data.addOns,
              },
            }
          );
        return res;
      }, []),
      {
        onSuccess: (res) => {
          window.location.replace(res.result);
        },
        onError: () => {
          message.error("something wrong when redirect to payment portal");
        },
      }
    );
  //#endregion

  //#region CANCEL SUBSCRIPTION
  const {
    mutateAsync: onCancelSubscription,
    isLoading: onCancelSubscriptionLoading,
  } = useMutation(
    useCallback(
      async ({ cancelAtEnd }) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1CancelSubscription(
            {
              cancelAtEnd: cancelAtEnd,
              subscriptionId: subscriptionId,
            }
          );
        return res;
      },
      [subscriptionId]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant"]);
        message.success("Your subscription successfully cancel");
        return res.success;
      },
      onError: () => {
        message.error("something wrong when redirect to payment portal");
      },
    }
  );
  //#endregion

  //#region RE-ACTIVE SUBSCRIPTION
  const {
    mutateAsync: onReactiveSubscription,
    isLoading: onReactiveSubscriptionLoading,
  } = useMutation(
    useCallback(async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1ReactivateSubscription(
          {
            subscriptionId: subscriptionId,
          }
        );
      return res;
    }, [subscriptionId]),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant"]);
        message.success("Your subscription successfully reactivated");
        return res.success;
      },
      onError: () => {
        message.error("something wrong when redirect to payment portal");
      },
    }
  );
  //#endregion

  const result = data?.result || [];

  return {
    data: result,
    loading:
      isFetching ||
      onCreateLoading ||
      onUpdateLoading ||
      onCancelSubscriptionLoading ||
      onReactiveSubscriptionLoading,
    onCreateSubscription,
    onUpdateSubscription,
    onCancelSubscription,
    onReactiveSubscription,
  };
};

export const useZohoSubscriptionCustomer = () => {
  //#region CREATE CUSTOMER
  const { mutateAsync: onCreateCustomer, isLoading: onCreateCustomerLoading } =
    useMutation(
      useCallback(async ({ email, tenantName }) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1CreateCustomer(
            {
              customer: {
                email: email,
                tenantName: tenantName,
              },
            }
          );
        return res;
      }, []),
      {
        onSuccess: (res) => {
          return;
        },
        onError: (err) => {
          message.error("something wrong when create customer");
        },
      }
    );
  //#endregion

  return {
    loading: onCreateCustomerLoading,
    onCreate: onCreateCustomer,
  };
};

export const useZohoSubscriptionsAddOns = () => {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const [allowToFetch, setAllowToFetch] = useState(true);

  const { data, isFetching } = useQuery({
    queryKey: ["zoho-addons"],
    queryFn: async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1GetAddOnsList();
      return res;
    },
    retry: async (count, error) => {
      if (count > 3) {
        return false;
      }
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
    onSuccess: () => {
      setAllowToFetch(false);
    },
    enabled: allowToFetch,
  });

  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};

export const useZohoSubscriptionsPlans = () => {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const [allowToFetch, setAllowToFetch] = useState(true);

  //? Get Add Ons
  const { data, isFetching } = useQuery({
    queryKey: ["zoho-plans"],
    queryFn: async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1GetPlanList();
      return res;
    },
    retry: async (count, error) => {
      if (count > 3) {
        return false;
      }
    },
    onSuccess: () => {
      setAllowToFetch(false);
    },
    onError: (err) => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!allowToFetch,
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};
