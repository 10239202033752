const { useQuery } = require("react-query");

const useAuthQuery = (...options) => {
  const query = useQuery(...options);

  if (query?.error?.response?.status === 401) {
    console.log("Access token get expired, logout...");
  }

  return query;
};

export default useAuthQuery;
