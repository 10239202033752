// Libs
import React, { useMemo } from "react";
import { Spin } from "antd";
import propType from "prop-types";

// Components
import MainAddOnsItem from "components/libs/addOns-item";

// Hooks
import { useZohoSubscriptionsAddOns } from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";

function DashboardCheckoutAddons({ onAdd, currentAddons = [] }) {
  //#region Add Ons Hooks
  const { data: addOnsList, loading: addOnsLoading } =
    useZohoSubscriptionsAddOns();
  const addOnsListParse = useMemo(() => {
    return addOnsList.sort((a, b) => a.name.localeCompare(b.name));
  }, [addOnsList]);
  //#endregion

  return (
    <>
      <Spin spinning={addOnsLoading}>
        <section className={s.mainSection}>
          {addOnsListParse.map((item, index) => {
            return (
              <section className={s.section} key={index}>
                <MainAddOnsItem
                  key={index}
                  details={item}
                  onChange={onAdd}
                  defaultQuantity={
                    currentAddons.find(
                      (target) => target?.code === item.addonCode
                    )?.quantity
                  }
                />
              </section>
            );
          })}
        </section>
      </Spin>
    </>
  );
}

DashboardCheckoutAddons.propType = {
  onAdd: propType.func,
  currentAddons: propType.array,
};

export default DashboardCheckoutAddons;
