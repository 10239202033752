// Libs
import { Outlet } from "react-router-dom";

// Components
import MainHeader from "components/layout/main/header";
import MainFooterV2 from "./footer/v2";
import BetaBanner from "components/libs/beta-banner";

function MainLayout(props) {
  const { children } = props;

  return (
    <>
      <MainHeader />
      <Outlet>{children}</Outlet>
      <MainFooterV2 />
      <BetaBanner />
    </>
  );
}

export default MainLayout;
