// Libs
import React, { useMemo } from "react";
import { Button, Col, Empty, Row, Spin } from "antd";
import { SettingFilled } from "@ant-design/icons";

// Components
import MainAddOnsItem from "components/libs/addOns-item";

// Hooks
import {
  useZohoSubscriptions,
  useZohoSubscriptionsAddOns,
} from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSDKTenants } from "hooks/tenants-sdk";

function MemberPageContentsAddOnsSection() {
  const navigate = useNavigate();

  //#region Tenants Hooks
  const { tenant } = useParams();
  const { data: tenantDetails } = useSDKTenants({ tenantName: tenant });
  const isSubscribed = useMemo(
    () => !!tenantDetails?.subscriptionId,
    [tenantDetails?.subscriptionId]
  );
  //#endregion

  //#region Subscriptions Hooks
  const { data: subscriptionDetails } = useZohoSubscriptions({
    subscriptionId: tenantDetails?.subscriptionId,
  });
  //#endregion

  //#region Add Ons Hooks
  const { data: addOnsList, loading: addOnsLoading } =
    useZohoSubscriptionsAddOns();

  const addOnsListParse = useMemo(() => {
    const result = addOnsList.filter((item) =>
      subscriptionDetails.addons?.find((find) => find.code === item.addonCode)
    );
    return result;
  }, [addOnsList, subscriptionDetails.addons]);
  //#endregion

  return (
    <>
      {!isSubscribed && (
        <Empty
          description={
            <>
              Please subscribe a plan <br /> to enable add-ons feature for this
              tenant!
            </>
          }
        >
          <Button
            onClick={() => {
              navigate("../subscription");
            }}
            type="primary"
            size="large"
          >
            Subscribe Now!
          </Button>
        </Empty>
      )}

      {isSubscribed && (
        <>
          {addOnsListParse.length > 0 && (
            <Col style={{ textAlign: "right" }}>
              <Button
                type="primary"
                size="large"
                icon={<SettingFilled />}
                onClick={() => {
                  navigate(`../../checkout?menu=addons`);
                }}
              >
                Manage Addons
              </Button>
            </Col>
          )}
          <Spin spinning={addOnsLoading}>
            <section className={s.mainSection}>
              {addOnsListParse.length === 0 && (
                <Empty
                  description={
                    <>
                      No add-ons on your tenant, <br /> add now to explore more
                      features
                    </>
                  }
                >
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      navigate("../../checkout?menu=addons");
                    }}
                  >
                    Add Now!
                  </Button>
                </Empty>
              )}

              <Row style={{ width: "100%" }}>
                {addOnsListParse.length > 0 &&
                  addOnsListParse.map((item, index) => {
                    return (
                      <section className={s.section} key={index}>
                        <Col span={24}>
                          <MainAddOnsItem
                            key={index}
                            details={item}
                            showAction={false}
                            showPrice={false}
                          />
                        </Col>
                      </section>
                    );
                  })}
              </Row>
            </section>
          </Spin>
        </>
      )}
    </>
  );
}

export default MemberPageContentsAddOnsSection;
