// Libs
import React from "react";
import { Col, Popconfirm, Row, Tag, Tooltip } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Styles
import s from "./index.module.scss";

// Icons
import { EditFilled, DeleteFilled, HistoryOutlined } from "@ant-design/icons";
import TableSearch from "components/libs/table-search";
import useQueryString from "hooks/useQueryString";
import FirewallUtilsStatus from "./status";

export const ControlPanelFirewallColumns = ({ onDelete, onUpdate }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = location.pathname;
  const query = useQueryString();

  const { getColumnSearchProps } = TableSearch();
  const statusColumn = FirewallUtilsStatus();

  //#region GCP Columns
  const gcpColumns = [
    {
      title: "ID",
      dataIndex: "firewallDestinationId",
      key: "firewallDestinationId",
      ...getColumnSearchProps("firewallDestinationId"),
    },
    {
      title: "Credential Name",
      dataIndex: "cloudId",
      key: "cloudId",
      ...getColumnSearchProps("cloudId", [
        query.get("cloudId") ? query.get("cloudId") : "",
      ]),
    },
    {
      title: "Location",
      dataIndex: "credentialLocation",
      key: "credentialLocation",
      render: (t, r) => {
        return (
          <>
            <div className={s.credentialLocation}>
              <span className={s.locationLogo}>
                <img src={"/gcloud-logo.png"} alt="gcp" />
              </span>
              {r.credentialLocation.toUpperCase()}
            </div>
          </>
        );
      },
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      ...getColumnSearchProps("projectName"),
      render: (t, r) => {
        return (
          <p className={s.projectName}>{r.projectName ? r.projectName : "-"}</p>
        );
      },
    },
    {
      title: "Update Mode",
      dataIndex: "updateMode",
      key: "updateMode",
      filters: [
        { text: "Append", value: "APPEND" },
        { text: "Track", value: "TRACK" },
      ],
      onFilter: (value, record) => record.updateMode === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "APPEND" ? "cyan" : "orange"}>
                {t === "APPEND" ? "APPEND" : "TRACK" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "Direction",
      dataIndex: "direction",
      key: "direction",
      filters: [
        { text: "Ingress", value: "INGRESS" },
        { text: "Egress", value: "EGRESS" },
      ],
      onFilter: (value, record) => record.direction === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "INGRESS" ? "green-inverse" : "pink-inverse"}>
                {t === "INGRESS" ? "INGRESS" : "EGRESS" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "VPC Name",
      dataIndex: "vpcName",
      key: "vpcName",
      render: (t, r) => {
        return <span className={s.vpcName}>{r.vpcName ? r.vpcName : "-"}</span>;
      },
    },
    {
      title: "Firewall Rule Name",
      dataIndex: "firewallRuleName",
      key: "firewallRuleName",
      ...getColumnSearchProps("firewallRuleName"),
      render: (t, r) => {
        return <span className={s.firewallRuleName}>{t}</span>;
      },
    },
    {
      title: "Firewall IP Version",
      dataIndex: "firewallIpVersion",
      key: "firewallIpVersion",
      filters: [
        { text: "IPv4", value: "IPv4" },
        { text: "IPv6", value: "IPv6" },
      ],
      onFilter: (value, record) => record.firewallIpVersion === value,
      render: (t, r) => {
        return <span className={s.firewallIpVersion}>{t || "-"}</span>;
      },
    },
    {
      title: "Placeholder IP Address",
      dataIndex: "placeholderIpAddress",
      key: "placeholderIpAddress",
      render: (t, r) => {
        return <span className={s.placeholderIpAddress}>{t}</span>;
      },
    },

    statusColumn,

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.action}>
              <Col className={s.actionIcon}>
                <Tooltip title="Edit">
                  <EditFilled onClick={() => onUpdate(r)} />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Popconfirm
                  placement="top"
                  title={"Are you sure delete these firewall destination?"}
                  onConfirm={() => onDelete(r.firewallDestinationId)}
                >
                  <Tooltip title="Delete">
                    <DeleteFilled />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="History">
                  <HistoryOutlined
                    onClick={() =>
                      navigate(`${path}/${r.firewallDestinationId}/history`)
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Linked Device",
      dataIndex: "linked_device",
      key: "linked_device",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <p
              className={s.manageFirewallLink}
              onClick={() =>
                navigate(
                  `/dashboard/${params.tenant}/manage-link?type=firewall&id=${r.firewallDestinationId}`
                )
              }
            >
              Manage Device Link
            </p>
          </>
        );
      },
    },
  ];
  //#endregion

  //#region Azure Columns
  const azureColumns = [
    {
      title: "ID",
      dataIndex: "firewallDestinationId",
      key: "firewallDestinationId",
      ...getColumnSearchProps("firewallDestinationId"),
    },
    {
      title: "Subscription ID",
      dataIndex: "cloudId",
      key: "cloudId",
      ...getColumnSearchProps("cloudId", [
        query.get("cloudId") ? query.get("cloudId") : "",
      ]),
    },
    {
      title: "Location",
      dataIndex: "credentialLocation",
      key: "credentialLocation",
      render: (t, r) => {
        return (
          <>
            <div className={s.credentialLocation}>
              <span className={s.locationLogo}>
                <img src={"/azure-logo.png"} alt="azure" />
              </span>
              {r.credentialLocation.toUpperCase()}
            </div>
          </>
        );
      },
    },
    {
      title: "Network Security Groups",
      dataIndex: "projectName",
      key: "projectName",
      ...getColumnSearchProps("projectName"),
      render: (t, r) => {
        return (
          <p className={s.projectName}>{r.projectName ? r.projectName : "-"}</p>
        );
      },
    },
    {
      title: "Firewall Rule Name",
      dataIndex: "firewallRuleName",
      key: "firewallRuleName",
      ...getColumnSearchProps("firewallRuleName"),
      render: (t, r) => {
        return <span className={s.firewallRuleName}>{t}</span>;
      },
    },
    {
      title: "Firewall IP Version",
      dataIndex: "firewallIpVersion",
      key: "firewallIpVersion",
      filters: [
        { text: "IPv4", value: "IPv4" },
        { text: "IPv6", value: "IPv6" },
      ],
      onFilter: (value, record) => record.firewallIpVersion === value,
      render: (t, r) => {
        return <span className={s.firewallIpVersion}>{t || "-"}</span>;
      },
    },
    {
      title: "Placeholder IP Address",
      dataIndex: "placeholderIpAddress",
      key: "placeholderIpAddress",
      render: (t, r) => {
        return <span className={s.placeholderIpAddress}>{t}</span>;
      },
    },
    {
      title: "Update Mode",
      dataIndex: "updateMode",
      key: "updateMode",
      filters: [
        { text: "Append", value: "APPEND" },
        { text: "Track", value: "TRACK" },
      ],
      onFilter: (value, record) => record.updateMode === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "APPEND" ? "cyan" : "orange"}>
                {t === "APPEND" ? "APPEND" : "TRACK" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "Direction",
      dataIndex: "direction",
      key: "direction",
      filters: [
        { text: "Inbound", value: "INGRESS" },
        { text: "Outbound", value: "EGRESS" },
      ],
      onFilter: (value, record) => record.direction === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "INGRESS" ? "green-inverse" : "pink-inverse"}>
                {t === "INGRESS" ? "INBOUND" : "OUTBOUND" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    statusColumn,
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.action}>
              <Col className={s.actionIcon}>
                <Tooltip title="Edit">
                  <EditFilled onClick={() => onUpdate(r)} />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Popconfirm
                  placement="top"
                  title={"Are you sure delete these firewall destination?"}
                  onConfirm={() => onDelete(r.firewallDestinationId)}
                >
                  <Tooltip title="Delete">
                    <DeleteFilled />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="History">
                  <HistoryOutlined
                    onClick={() =>
                      navigate(`${path}/${r.firewallDestinationId}/history`)
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Linked Device",
      dataIndex: "linked_device",
      key: "linked_device",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <p
              className={s.manageFirewallLink}
              onClick={() =>
                navigate(
                  `/dashboard/${params.tenant}/manage-link?type=firewall&id=${r.firewallDestinationId}`
                )
              }
            >
              Manage Device Link
            </p>
          </>
        );
      },
    },
  ];
  //#endregion

  //#region AWS Columns
  const awsColumns = [
    {
      title: "ID",
      dataIndex: "firewallDestinationId",
      key: "firewallDestinationId",
      ...getColumnSearchProps("firewallDestinationId"),
    },
    {
      title: "Credential Name",
      dataIndex: "cloudId",
      key: "cloudId",
      ...getColumnSearchProps("cloudId", [
        query.get("cloudId") ? query.get("cloudId") : "",
      ]),
    },
    {
      title: "Location",
      dataIndex: "credentialLocation",
      key: "credentialLocation",
      render: (t, r) => {
        return (
          <>
            <div className={s.credentialLocation}>
              <span className={s.locationLogo}>
                <img src={"/aws-logo.png"} alt="aws" />
              </span>
              {r.credentialLocation.toUpperCase()}
            </div>
          </>
        );
      },
    },
    {
      title: "Network Security Groups",
      dataIndex: "projectName",
      key: "projectName",
      ...getColumnSearchProps("projectName"),
      render: (t, r) => {
        return (
          <p className={s.projectName}>{r.projectName ? r.projectName : "-"}</p>
        );
      },
    },
    {
      title: "Firewall Rule Name",
      dataIndex: "firewallRuleName",
      key: "firewallRuleName",
      ...getColumnSearchProps("firewallRuleName"),
      render: (t, r) => {
        return <span className={s.firewallRuleName}>{t}</span>;
      },
    },
    {
      title: "Firewall IP Version",
      dataIndex: "firewallIpVersion",
      key: "firewallIpVersion",
      filters: [
        { text: "IPv4", value: "IPv4" },
        { text: "IPv6", value: "IPv6" },
      ],
      onFilter: (value, record) => record.firewallIpVersion === value,
      render: (t, r) => {
        return <span className={s.firewallIpVersion}>{t || "-"}</span>;
      },
    },
    {
      title: "Placeholder IP Address",
      dataIndex: "placeholderIpAddress",
      key: "placeholderIpAddress",
      render: (t, r) => {
        return <span className={s.placeholderIpAddress}>{t}</span>;
      },
    },
    {
      title: "Direction",
      dataIndex: "direction",
      key: "direction",
      filters: [
        { text: "Inbound", value: "INGRESS" },
        { text: "Outbound", value: "EGRESS" },
      ],
      onFilter: (value, record) => record.direction === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "INGRESS" ? "green-inverse" : "pink-inverse"}>
                {t === "INGRESS" ? "INBOUND" : "OUTBOUND" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "Update Mode",
      dataIndex: "updateMode",
      key: "updateMode",
      filters: [
        { text: "Append", value: "APPEND" },
        { text: "Track", value: "TRACK" },
      ],
      onFilter: (value, record) => record.updateMode === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "APPEND" ? "cyan" : "orange"}>
                {t === "APPEND" ? "APPEND" : "TRACK" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    statusColumn,
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.action}>
              <Col className={s.actionIcon}>
                <Tooltip title="Edit">
                  <EditFilled onClick={() => onUpdate(r)} />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Popconfirm
                  placement="top"
                  title={"Are you sure delete these firewall destination?"}
                  onConfirm={() => onDelete(r.firewallDestinationId)}
                >
                  <Tooltip title="Delete">
                    <DeleteFilled />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="History">
                  <HistoryOutlined
                    onClick={() =>
                      navigate(`${path}/${r.firewallDestinationId}/history`)
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Linked Device",
      dataIndex: "linked_device",
      key: "linked_device",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <p
              className={s.manageFirewallLink}
              onClick={() =>
                navigate(
                  `/dashboard/${params.tenant}/manage-link?type=firewall&id=${r.firewallDestinationId}`
                )
              }
            >
              Manage Device Link
            </p>
          </>
        );
      },
    },
  ];
  //#endregion

  return {
    gcpColumns,
    azureColumns,
    awsColumns,
  };
};
