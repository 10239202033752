// Libs
import moment from "moment";
import React from "react";
import { Col, Popconfirm, Row, Tag, Tooltip } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// Styles
import s from "./utils.module.scss";

// Icons
import {
  EditFilled,
  ApiOutlined,
  HistoryOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import TableSearch from "components/libs/table-search";

export const ControlPanelDevicesColumns = ({
  onUnlink,
  setEditValue,
  showUpdateModal,
  showTransferModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = location.pathname;

  const { getColumnSearchProps } = TableSearch();

  //#region Handle Unlink
  const handleUnlink = (id) => {
    onUnlink({ deviceId: id });
  };
  //#endregion

  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Client UUID",
      dataIndex: "client",
      key: "client",
      render: (t, r) => {
        return <>{r.clientUUID ? <Tag>{r.clientUUID}</Tag> : "-"}</>;
      },
      ...getColumnSearchProps("client"),
    },
    {
      title: "Hostname",
      dataIndex: "hostname",
      key: "hostname",
      render: (t, r) => {
        return (
          <div style={{ minWidth: 200 }}>{r.hostName ? r.hostName : "-"}</div>
        );
      },
      ...getColumnSearchProps("hostname"),
    },
    {
      title: "IPv4",
      dataIndex: "ipv4",
      key: "ipv4",
      render: (t, r) => {
        const IPv4List =
          r.ipv4 &&
          r.ipv4 !== "[]" &&
          r?.ipv4?.replace(/[[\]\s]+/g, "")?.split(",");
        return (
          <>
            <Row gutter={[0, 16]} style={{ minWidth: 200 }}>
              {IPv4List
                ? IPv4List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </>
        );
      },
      ...getColumnSearchProps("ipv4"),
    },
    {
      title: "IPv6",
      dataIndex: "ipv6",
      key: "ipv6",
      render: (t, r) => {
        const IPv6List =
          r.ipv6 &&
          r.ipv6 !== "[]" &&
          r?.ipv6?.replace(/[[\]\s]+/g, "")?.split(",");
        return (
          <>
            <Row gutter={[0, 16]} style={{ minWidth: 200 }}>
              {IPv6List
                ? IPv6List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </>
        );
      },
      ...getColumnSearchProps("ipv6"),
    },
    {
      title: "Last Update",
      dataIndex: "last_update",
      key: "last_update",
      render: (t, r) => {
        return (
          <div className={s.lastUpdate} style={{ minWidth: 200 }}>
            <p>{moment(r?.last_update).format("DD MMMM YYYY")}</p>
            <p>{moment(r?.last_update).format("hh:mm:ss")}</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row
              gutter={[16, 0]}
              className={s.actionColumn}
              style={{ minWidth: 170 }}
            >
              <Col className={s.actionIcon}>
                <Tooltip title="Edit">
                  <EditFilled
                    onClick={() => {
                      showUpdateModal();
                      setEditValue(r);
                    }}
                  />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Popconfirm
                  placement="top"
                  title={"Are you sure unlink this device?"}
                  onConfirm={() => handleUnlink(r.id)}
                >
                  <Tooltip title="Unlink">
                    <ApiOutlined />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="History">
                  <HistoryOutlined
                    onClick={() => navigate(`${path}/${r.id}/history`)}
                  />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="Transfer">
                  <SwapOutlined onClick={() => showTransferModal(r.id)} />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Firewall Link",
      dataIndex: "firewall_link",
      key: "firewall_link",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <p
              className={s.manageFirewallLink}
              onClick={() =>
                navigate(
                  `/dashboard/${params.tenant}/manage-link?type=device&id=${r.id}`
                )
              }
            >
              Manage Firewall Link
            </p>
          </>
        );
      },
    },
  ];
};
