// Libs
import React, { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Segmented,
  Spin,
  Tooltip,
} from "antd";

// Hooks
import {
  useZohoSubscriptions,
  useZohoSubscriptionsPlans,
} from "hooks/zoho-integration-sdk";

// Components
import PricingListItem from "components/libs/price-list-item";

// Styles
import s from "./index.module.scss";
import { pricingContents } from "data/homepage";

// Icons
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  SettingFilled,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSDKTenants } from "hooks/tenants-sdk";
import { stringCapitalize } from "utils/stringCapitalize";
import { useSDKUsers } from "hooks/user-sdk";

function DashboardSettingsSubscription() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { Panel } = Collapse;

  //#region Users Hook
  const { data: userDetails } = useSDKUsers({ externalUserId: user?.sub });
  const trialAvailable = useMemo(() => {
    return userDetails?.trialAvailable;
  }, [userDetails?.trialAvailable]);
  //#endregion

  //#region Tenants Hook
  const { data: tenantDetails } = useSDKTenants({ tenantName: params.tenant });
  const isSubscribed = useMemo(
    () => !!tenantDetails?.subscriptionId,
    [tenantDetails?.subscriptionId]
  );
  //#endregion

  //#region Subscription Hooks
  const { onCreateSubscription, loading: onSubscriptionLoading } =
    useZohoSubscriptions({
      subscriptionId: tenantDetails?.subscriptionId || "",
    });
  //#endregion

  //#region Handle Segment
  const [segmentSelected, isSegmentSelected] = useState("Monthly");
  const handleSegment = (value) => {
    isSegmentSelected(value);
  };
  //#endregion

  //#region Handle Subscription
  const handleSubscription = ({ planCode, interval }) => {
    if (isSubscribed) {
      navigate(`../../checkout?plan=${planCode}&interval=${interval}`);
    } else {
      const submission = {
        email: user?.email,
        tenantName: params.tenant,
        customerId: tenantDetails?.customerId,
        planCode: planCode,
        excludeTrial: !(trialAvailable > 0),
      };
      onCreateSubscription(submission);
    }
  };
  //#endregion

  //#region  Zoho Plan list
  const { data: zohoPlanList, loading: zohoPlanListLoading } =
    useZohoSubscriptionsPlans();
  //#endregion

  const priceListType = ["free", "advance", "pro", "enterprise"];

  return (
    <>
      {isSubscribed && (
        <Col style={{ textAlign: "right" }}>
          <Button
            type="primary"
            size="large"
            icon={<SettingFilled />}
            onClick={() => {
              handleSubscription({
                planCode: tenantDetails?.plan,
                interval: tenantDetails?.planInterval,
              });
            }}
          >
            Manage Subscription
          </Button>
        </Col>
      )}
      <Col span={24} className={s.selection}>
        <Segmented
          options={["Monthly", "Annualy"]}
          onChange={handleSegment}
          defaultValue={"Monthly"}
        />
      </Col>
      <Spin spinning={onSubscriptionLoading}>
        <div style={{ padding: "0 30px", marginTop: "30px" }}>
          <Row gutter={[16, 16]} justify={"center"} align={"stretch"}>
            {pricingContents.modes.map((item, index) => {
              return (
                <Col span={8} key={index}>
                  <PricingListItem
                    title={item.name}
                    price={
                      segmentSelected === "Monthly"
                        ? zohoPlanList?.find(
                            (zohoPlan) =>
                              zohoPlan.planCode === item.monthly_plan_code
                          )?.recurringPrice || "Contact Us"
                        : zohoPlanList?.find(
                            (zohoPlan) =>
                              zohoPlan.planCode === item.annual_plan_code
                          )?.recurringPrice || "Contact Us"
                    }
                    priceLoading={zohoPlanListLoading}
                    description={item.subHead}
                    logo={item.logo}
                    features={item.features}
                    planCode={
                      segmentSelected === "Monthly"
                        ? item.monthly_plan_code
                        : item.annual_plan_code
                    }
                    onCheckout={handleSubscription}
                    currentPlan={tenantDetails?.plan || "free"}
                    interval={segmentSelected === "Monthly" ? "Month" : "Year"}
                    isTrial={trialAvailable > 0 && item.trial_available}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </Spin>

      <section className={s.priceDetailsSection}>
        <Row>
          <Col span={4} />
          {priceListType.map((item) => {
            return (
              <Col
                span={5}
                className={`${
                  tenantDetails?.plan?.includes(item) ||
                  (!tenantDetails?.plan && item === "free")
                    ? s.selectedPlan
                    : ""
                } ${s.planTitle}`}
              >
                <h1>{stringCapitalize(item)}</h1>
              </Col>
            );
          })}
        </Row>
        <Collapse collapsible="header" defaultActiveKey={["0"]}>
          {pricingContents.subHeads.map((item, index) => {
            return (
              <Panel header={item.name} key={[index]} className={s.panel}>
                {item.properties.map((property, propIndex) => {
                  return (
                    <div className={s.detailsItemsContainer} key={propIndex}>
                      <Row gutter={[16, 0]} align={"middle"}>
                        <Col span={4}>
                          <div className={s.tableTitle}>
                            <strong>{property.title} </strong>
                            {property.description ? (
                              <span>
                                <Tooltip title={property.description}>
                                  <InfoCircleFilled />
                                </Tooltip>
                              </span>
                            ) : null}
                          </div>
                        </Col>
                        {priceListType.map((type) => {
                          return (
                            <Col
                              span={5}
                              className={`${
                                tenantDetails?.plan?.includes(type) ||
                                (!tenantDetails?.plan && type === "free")
                                  ? s.selectedPlan
                                  : ""
                              } ${s.planDetails}`}
                            >
                              <div className={s.detailsItems}>
                                {pricingContents?.[stringCapitalize(type)]?.[
                                  item.name
                                ]?.[property.title] === true ? (
                                  <div className={s.checkIcon}>
                                    <CheckCircleFilled />
                                  </div>
                                ) : pricingContents?.[stringCapitalize(type)]?.[
                                    item.name
                                  ]?.[property.title] === false ? (
                                  <div className={s.crossIcon}>
                                    <CloseCircleFilled />
                                  </div>
                                ) : (
                                  <p>
                                    {
                                      pricingContents?.[
                                        stringCapitalize(type)
                                      ]?.[item.name]?.[property.title]
                                    }
                                  </p>
                                )}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                      <Divider className={s.divider} />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </section>
    </>
  );
}

DashboardSettingsSubscription.propTypes = {};

export default DashboardSettingsSubscription;
