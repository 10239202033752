// Libs
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Spin, Table, Tour, Skeleton, Divider } from "antd";
import { useParams, useNavigate } from "react-router-dom";

// Components
import { ControlPanelCredentialsColumns } from "./utils";
import ModalAddCredentials from "components/libs/modal/add-credentials";
import ModalAddAwsCredentials from "components/libs/modal/add-aws-credentials";
import PageHeader from "components/libs/page-header";

// Styles
import s from "./index.module.scss";

// Hooks
import { useSDKCredentials } from "hooks/credentials-sdk";
import useQueryString from "hooks/useQueryString";

// Data
import { credentialsDataSample } from "data/control-panel";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";
import ModalAddAzureCredentials from "components/libs/modal/add-azure-credentials";
import CompLibsCredentialsLocationTitle from "components/libs/credentials-location-title";

function DashboardControlPanelCredentials(props) {
  const params = useParams();
  const navigate = useNavigate();
  const tenantName = params.tenant;
  const query = useQueryString();
  const mode = query.get("mode");
  const selectedId = query.get("credId");
  const errorCode = query.get("errorCode");

  //#region  Handle Add Credentials
  const [visible, setVisible] = useState(false);
  const handleShowModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);
  //#endregion

  //#region  Credentials Hook
  const {
    data: credentialsData,
    onAddGcp,
    onAddAws,
    onAddAzure,
    onUpdateGcp,
    onUpdateAws,
    onUpdateAzure,
    onDelete,
    loading,
  } = useSDKCredentials({
    tenantName: tenantName,
    type: "",
  });
  //#endregion

  //#region   Handle Update Credential GCP
  const handleUpdateGcpCredential = (id, cloudId) => {
    const submission = {
      redirectUri: `${window.location.origin}/dashboard/${tenantName}/credentials`,
      cloudId: cloudId,
    };
    onUpdateGcp({ id: id, submission: submission });
  };
  //#endregion

  //#region   Handle Update Credentials AWS
  const [showAwsUpdateModal, setShowAwsUpdateModal] = useState(false);
  const [selectedAwsDetails, setSelectedAwsDetails] = useState();

  //? Handle Show Modal
  const handleShowAwsUpdateModal = (data) => {
    setShowAwsUpdateModal(!showAwsUpdateModal);
    setSelectedAwsDetails(data);
  };

  const handleUpdateAws = async (value) => {
    const submission = {
      cloudId: selectedAwsDetails.cloudId,
      tenantName: tenantName,
      ...value,
    };
    await onUpdateAws({
      id: selectedAwsDetails.credentialId,
      submission: submission,
    });
    setShowAwsUpdateModal(false);
  };
  //#endregion

  //#region  Handle update azure credentials
  const [showAzureUpdateModal, setShowAzureUpdateModal] = useState(false);
  const [selectedAzureDetails, setSelectedAzureDetails] = useState();

  const handleShowAzureUpdateModal = (data) => {
    setShowAzureUpdateModal(!showAzureUpdateModal);
    setSelectedAzureDetails(data);
  };

  const handleUpdateAzure = (value) => {
    const submission = {
      azureSubscriptionId: value.azureSubscriptionId,
      azureTenantId: value.azureTenantId,
      clientId: value.clientId,
      clientSecret: value.clientSecret,
    };
    onUpdateAzure({
      id: selectedAzureDetails.credentialId,
      submission: submission,
    });
    setShowAzureUpdateModal(false);
  };
  //#endregion

  //#region  Handle Delete
  const handleDelete = (id) => {
    onDelete(id);
  };
  //#endregion

  //#region  Handle Columns
  const columns = ControlPanelCredentialsColumns({
    onDelete: handleDelete,
    onUpdateGcp: handleUpdateGcpCredential,
    onUpdateAws: handleShowAwsUpdateModal,
    onUpdateAzure: handleShowAzureUpdateModal,
  });
  //#endregion

  //#region  Handle Tour
  const [targetTour, setTargetTour] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selectedId) {
      const target = document.getElementById("credentials-edit-highlight");
      if (target && !targetTour) {
        setTargetTour(target);
      }
    }
  });
  const [tourOpen, setTourOpen] = useState(selectedId);
  //#endregion

  //#region  Handle error after create
  useEffect(() => {
    if (errorCode) {
      if (errorCode === "CREDENTIAL_ALREADY_EXISTS") {
        NotificationErrorSomethingWrong("This credentials is already exists");
      } else {
        navigate(`/dashboard/${tenantName}/retry-add-credential`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, tenantName]);
  //#endregion

  //#region  Handle auto create credentials by mode
  const [createBy, setCreateBy] = useState("options");
  useEffect(() => {
    switch (mode) {
      case "created-by-aws":
        setCreateBy("aws");
        handleShowModal();
        break;

      case "created-by-azure":
        setCreateBy("azure");
        handleShowModal();
        break;

      case "created-by-gcp":
        setCreateBy("gcp");
        handleShowModal();
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
  //#endregion
  return (
    <>
      <PageHeader
        title="Credentials List"
        className={s.header}
        extra={[
          <Button size="large" onClick={handleShowModal}>
            + Add Credentials
          </Button>,
        ]}
      />

      <CompLibsCredentialsLocationTitle context="Credentials" type="gcp" />
      <Spin spinning={loading}>
        {credentialsData && (
          <Table
            columns={columns}
            dataSource={
              !tenantName.includes("<")
                ? credentialsData?.filter((item) => item.location === "GCP")
                : credentialsDataSample
            }
            scroll={{ x: 1200 }}
            tableLayout="auto"
            rowClassName={(record, index) => {
              return mode === "edit" && record?.credentialId === +selectedId
                ? s.highlight
                : "";
            }}
          />
        )}
      </Spin>
      <Divider />

      <CompLibsCredentialsLocationTitle context="Credentials" type="azure" />
      <Spin spinning={loading}>
        {credentialsData && (
          <Table
            columns={columns}
            dataSource={
              !tenantName.includes("<")
                ? credentialsData?.filter((item) => item.location === "AZURE")
                : credentialsDataSample
            }
            scroll={{ x: 1200 }}
            tableLayout="auto"
            rowClassName={(record, index) => {
              return mode === "edit" && record?.credentialId === +selectedId
                ? s.highlight
                : "";
            }}
          />
        )}
      </Spin>
      <Divider />

      <CompLibsCredentialsLocationTitle context="Credentials" type="aws" />
      <Spin spinning={loading}>
        {credentialsData && (
          <Table
            columns={columns}
            dataSource={
              !tenantName.includes("<")
                ? credentialsData?.filter((item) => item.location === "AWS")
                : credentialsDataSample
            }
            scroll={{ x: 1200 }}
            tableLayout="auto"
            rowClassName={(record, index) => {
              return mode === "edit" && record?.credentialId === +selectedId
                ? s.highlight
                : "";
            }}
          />
        )}
      </Spin>
      <Divider />

      <ModalAddCredentials
        visibility={visible}
        handleVisible={handleShowModal}
        handleAddGcp={onAddGcp}
        handleAddAws={onAddAws}
        handleAddAzure={onAddAzure}
        initialInterface={createBy}
        loading={loading}
      />

      <Modal
        open={showAwsUpdateModal}
        title="Update Aws Credentials"
        footer={false}
        onCancel={handleShowAwsUpdateModal}
      >
        {selectedAwsDetails ? (
          <ModalAddAwsCredentials onAdd={handleUpdateAws} isEdit={true} />
        ) : (
          <Spin spinning={loading}>
            <Skeleton />
          </Spin>
        )}
      </Modal>

      <Modal
        open={showAzureUpdateModal}
        title="Update Azure Credentials"
        footer={false}
        onCancel={handleShowAzureUpdateModal}
      >
        {selectedAzureDetails ? (
          <ModalAddAzureCredentials
            onAdd={handleUpdateAzure}
            initialValue={selectedAzureDetails}
          />
        ) : (
          <Spin spinning={loading}>
            <Skeleton />
          </Spin>
        )}
      </Modal>

      {targetTour ? (
        <Tour
          open={tourOpen}
          onClose={() => {
            setTourOpen(false);
          }}
          className={"credentials-tour"}
          steps={[
            {
              title: "Update your Credentials!",
              description:
                "To continue work with this credentials, need to update by click this button",
              target: targetTour,
              placement: "bottomLeft",
              nextButtonProps: () => {},
            },
          ]}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default DashboardControlPanelCredentials;
