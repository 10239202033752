import { Affix, Card, Col, Menu, Row, Spin } from "antd";

import { useEffect, useMemo, useState } from "react";
import DashboardCheckoutPlan from "./plan";
import DashboardCheckoutAddons from "./addons";
import DashboardCheckoutSummary from "./summary";
import { useParams } from "react-router-dom";
import { useSDKTenants } from "hooks/tenants-sdk";
import {
  useZohoSubscriptions,
  useZohoSubscriptionsAddOns,
  useZohoSubscriptionsPlans,
} from "hooks/zoho-integration-sdk";
import useQueryString from "hooks/useQueryString";

export default function PagesDashboardCheckout() {
  const params = useParams();
  const queryString = useQueryString();
  const planCode = queryString.get("planCode");
  const defaultMenu = queryString.get("menu") || "plan";

  //#region Handle Context
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [total, setTotal] = useState(0);
  //#endregion

  //#region Tenants Hook
  const { data: tenantDetails } = useSDKTenants({
    tenantName: params.tenant,
  });
  //#endregion

  //#region Subscription Hooks
  const {
    data: subscriptionDetails,
    onUpdateSubscription,
    loading: subscriptionDetailsLoading,
  } = useZohoSubscriptions({
    subscriptionId: tenantDetails?.subscriptionId || "",
  });
  //#endregion

  //#region Add ons Hooks
  const { data: addOnsList, loading: addOnsLoading } =
    useZohoSubscriptionsAddOns();
  //#endregion

  //#region Plan Hooks
  const { data: planList } = useZohoSubscriptionsPlans();
  const currentPlan = useMemo(() => {
    if (planList && tenantDetails) {
      return planList.find((item) => item.planCode === tenantDetails.plan);
    }
  }, [planList, tenantDetails]);
  //#endregion

  //#region Handle select Menu
  const [selectedMenu, setSelectedMenu] = useState(defaultMenu);
  const handleSelectMenu = (value) => {
    setSelectedMenu(value.key);
  };
  //#endregion

  //#region Handle Addons
  const handleUpdateAddons = ({ data }) => {
    const prevAddon = selectedAddons?.filter((item) => item.code !== data.code);
    const additionalData = data.quantity !== 0 && data;
    const newSelectedAddons = [...prevAddon, additionalData]
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name));

    setSelectedAddons(newSelectedAddons);
  };
  //#endregion

  //#region Handle Checkout
  const handleCheckout = () => {
    const submission = {
      planCode: selectedPlan?.planCode,
      subscriptionId: tenantDetails?.subscriptionId,
      addOns: selectedAddons?.map((item) => {
        return { addOnsCode: item.code, quantity: `${item.quantity}` };
      }),
    };
    onUpdateSubscription(submission);
  };
  //#endregion

  //#region Initialize selectedAddons
  useEffect(() => {
    if (subscriptionDetails?.addons?.length > 0 && addOnsList) {
      const initialSubscriptionList = subscriptionDetails?.addons.map(
        (item) => ({
          ...item,
          price: addOnsList.find((addon) => addon.addonCode === item.code)
            ?.price,
        })
      );

      setSelectedAddons(initialSubscriptionList);
    }
  }, [subscriptionDetails.addons, addOnsList]);
  //#endregion

  //#region Initialize plan
  useEffect(() => {
    if (planCode) {
      const initialPlan = planList.find((item) => item.planCode === planCode);
      setSelectedPlan(initialPlan);
      return;
    }

    if (currentPlan) {
      setSelectedPlan(currentPlan);
      return;
    }
  }, [currentPlan, planCode, planList]);
  //#endregion

  //#region Handle total (monthly & yearly)
  useEffect(() => {
    const totalAddons =
      selectedAddons
        .map((item) => item.price * item.quantity)
        .reduce((a, b) => a + b, 0) *
      (selectedPlan.intervalUnit === "months" ? 1 : 12);

    const planPriceMonthly = selectedPlan?.recurringPrice;

    setTotal(totalAddons + planPriceMonthly);
  }, [
    selectedAddons,
    selectedPlan?.intervalUnit,
    selectedPlan?.recurringPrice,
  ]);
  //#endregion

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={15}>
          <Card title="Checkout Details">
            <Menu
              items={[
                { label: "Subscription Plan", key: "plan" },
                { label: "Add Ons", key: "addons" },
              ]}
              mode="horizontal"
              onClick={handleSelectMenu}
              selectedKeys={selectedMenu}
            />
            {selectedMenu === "plan" && (
              <Spin spinning={!currentPlan}>
                <DashboardCheckoutPlan
                  onSelect={setSelectedPlan}
                  selected={selectedPlan?.planCode}
                />
              </Spin>
            )}
            {selectedMenu === "addons" && (
              <DashboardCheckoutAddons
                onAdd={handleUpdateAddons}
                currentAddons={selectedAddons}
              />
            )}
          </Card>
        </Col>
        <Col span={9}>
          <Spin spinning={addOnsLoading || subscriptionDetailsLoading}>
            <Affix offsetTop={20}>
              <DashboardCheckoutSummary
                total={total}
                onCheckout={handleCheckout}
                addonsList={selectedAddons}
                selectedPlan={selectedPlan}
                currentPlan={currentPlan}
                currentAddonsList={subscriptionDetails?.addons}
              />
            </Affix>
          </Spin>
        </Col>
      </Row>
    </>
  );
}
