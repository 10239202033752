// Libs
import React, { useEffect, useState } from "react";
import { Card, Menu } from "antd";
import { Link, useLocation, Outlet } from "react-router-dom";

// Styles
import s from "./index.module.scss";

function DashboardSettings(props) {
  const { children } = props;
  const location = useLocation();

  //#region  Menu Item
  const MenuItems = [
    {
      label: <Link to={`./`}>General</Link>,
      key: `settings`,
    },
    {
      label: <Link to={`./subscription`}>Subscription</Link>,
      key: `subscription`,
    },
    {
      label: <Link to={`./add-ons`}>Add Ons</Link>,
      key: `add-ons`,
    },
    {
      label: <Link to={`./members`}>Members</Link>,
      key: `members`,
    },
    {
      label: <Link to={`./advanced`}>Advanced</Link>,
      key: `advanced`,
    },
  ];
  //#endregion

  //#region Handle Menu Highlight
  const [currentUrl, setCurrentUrl] = useState(false);
  const handleSelectMenu = ({ key }) => {
    setCurrentUrl(key);
  };
  useEffect(() => {
    setCurrentUrl(location.pathname.split("/")?.[4] || "settings");
  }, [location.pathname]);
  //#endregion

  return (
    <>
      <Card title="Tenants Settings">
        <Menu
          items={MenuItems}
          mode="horizontal"
          selectedKeys={[currentUrl || location.pathname]}
          onSelect={handleSelectMenu}
          className={s.menu}
        />

        <div style={{ padding: "30px 20px 0" }}>
          <Outlet>{children}</Outlet>
        </div>
      </Card>
    </>
  );
}

export default DashboardSettings;
