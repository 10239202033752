// Libs
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";

// Styles
import s from "./index.module.scss";

function ModalUpdateDevice(props) {
  const { visible, handleVisible, onUpdate, currentValue } = props;

  //#region Handle Submit Without Agent
  const [hostName, setHostName] = useState();
  const handleChangeHostName = (e) => {
    setHostName(e.target.value);
  };
  const handleSubmitWithoutAgent = () => {
    onUpdate({ id: currentValue.id, submission: { hostName: hostName } });
    handleVisible();
  };
  //#endregion

  //#region Initialize Data \
  useEffect(() => {
    setHostName(currentValue.hostName);
  }, [currentValue.hostName]);
  //#endregion

  return (
    <>
      <Modal
        title="Update Device"
        open={visible}
        onCancel={() => {
          setHostName();
          handleVisible();
        }}
        footer={false}
      >
        <div className={`${s.withoutAgentContainer} ${s.contentContainer}`}>
          <p>Hostname :</p>
          <Input
            placeholder="Input your hostname"
            value={hostName}
            onChange={handleChangeHostName}
          />
          <div className={s.withoutAgentButton}>
            <Button type="primary" onClick={handleSubmitWithoutAgent}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

ModalUpdateDevice.propTypes = {
  visible: propTypes.bool,
  handleVisible: propTypes.func,
  onUpdate: propTypes.func,
  currentValue: propTypes.any,
};

export default ModalUpdateDevice;
