// Libs
import React from "react";
import { Button, Col, Row, Tag } from "antd";

// Icons
import { DisconnectOutlined, LinkOutlined } from "@ant-design/icons";

// Styles
import s from "./utils.module.scss";
import moment from "moment-timezone";
import NotificationWarningExpiredCredentials from "components/libs/notifications/expired-credentials";
import { useNavigate, useParams } from "react-router-dom";

export const DeviceManageLinkColumns = ({ status, onLink }) => {
  const params = useParams();
  const navigate = useNavigate();
  const tenantName = params.tenant;

  return [
    {
      title: "Credential Name",
      dataIndex: "cloudId",
      key: "cloudId",
      render: (t, r) => {
        return (
          <p className={s.projectName}>
            {r?.credential?.cloudId ? r?.credential?.cloudId : "-"}
          </p>
        );
      },
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (t, r) => {
        return (
          <p className={s.projectName}>
            {r?.projectName ? r?.projectName : "-"}
          </p>
        );
      },
    },
    {
      title: "VPC Name",
      dataIndex: "vpcName",
      key: "vpcName",
      render: (t, r) => {
        return (
          <span className={s.vpcName}>{r?.vpcName ? r?.vpcName : "-"}</span>
        );
      },
    },
    {
      title: "Firewall Rule Name",
      dataIndex: "firewallRuleName",
      key: "firewallRuleName",
      render: (t, r) => {
        return <span className={s.firewallRuleName}>{t || "-"}</span>;
      },
    },
    {
      title: "Firewall IP Version",
      dataIndex: "firewallIpVersion",
      key: "firewallIpVersion",
      render: (t, r) => {
        return <span className={s.firewallIpVersion}>{t || "-"}</span>;
      },
    },
    {
      title: "Placeholder IP Address",
      dataIndex: "placeholderIpAddress",
      key: "placeholderIpAddress",
      render: (t, r) => {
        return <span className={s.placeholderIpAddress}>{t || "-"}</span>;
      },
    },
    {
      title: "Update Mode",
      dataIndex: "updateMode",
      key: "updateMode",
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "APPEND" ? "cyan" : "orange"}>
                {t === "APPEND" ? "APPEND" : "TRACK" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.actionColumn}>
              <Col className={s.actionIcon}>
                <Button
                  onClick={() => {
                    if (
                      moment().isAfter(
                        moment(r.credential.lastUpdate).add(7, "days")
                      )
                    ) {
                      const handleRedirect = () => {
                        navigate(
                          `/dashboard/${tenantName}/credentials?mode=edit&credId=${r?.credential?.credentialId}`
                        );
                      };
                      NotificationWarningExpiredCredentials({
                        onClick: handleRedirect,
                      });
                    } else {
                      onLink(r.firewallDestinationId);
                    }
                  }}
                  type="primary"
                  danger={status === "available" ? false : true}
                  icon={
                    status === "available" ? (
                      <LinkOutlined />
                    ) : (
                      <DisconnectOutlined />
                    )
                  }
                >
                  {status === "available" ? "Link" : "Unlink"}
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
};
