import { Col, Row, Select } from "antd";
import { useZohoSubscriptionsPlans } from "hooks/zoho-integration-sdk";
import propTypes from "prop-types";
const { Option } = Select;

export default function DashboardCheckoutPlan(props) {
  const { onSelect = () => {}, selected } = props;
  const { data: planList, loading: planListLoading } =
    useZohoSubscriptionsPlans();

  return (
    <>
      <Row gutter={[16, 0]} style={{ marginTop: 20 }}>
        <Col>Select Plan:</Col>
        <Col>
          <Select
            popupMatchSelectWidth
            style={{ minWidth: "200px" }}
            placeholder="Select plan here"
            loading={planListLoading}
            value={selected}
            onSelect={(value) => {
              const selectedPlan = planList.find(
                (item) => item.planCode === value
              );
              onSelect(selectedPlan);
            }}
          >
            {planList.map((item) => {
              return <Option key={item.planCode}>{item.name}</Option>;
            })}
          </Select>
        </Col>
      </Row>
    </>
  );
}

DashboardCheckoutPlan.propTypes = {
  onSelect: propTypes.func,
  selected: propTypes.string,
};
