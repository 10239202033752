import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";
import { useCallback } from "react";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";
import { usePlanSuggestion } from "context/plan-suggestion";

export const useSDKTenantUsers = ({ tenantName }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const navigate = useNavigate();

  const { setOpenModal, setTopic, setTenantName } = usePlanSuggestion();

  //#region  Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["tenant-user"],
    queryFn: async () => {
      const res = await apiSDK.tenantUserApi.getTenantUsersByTenantName({
        tenantName: tenantName,
      });
      return res;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });
  //#endregion

  //#region  Add new member
  const { mutateAsync: onAddMember, isLoading: onAddMemberLoading } =
    useMutation(
      useCallback(
        async (data) => {
          const res = await apiSDK.tenantUserApi.createTenantUser({
            v1CreateTenantUserForm: {
              email: data.email,
              tenantName: tenantName,
            },
          });
          return res;
        },
        [tenantName]
      ),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(["tenant-user"]);
          notification.success({
            message: "Successfully",
            description: "successfully add new member to your tenant",
          });
          return res;
        },
        onError: async (err) => {
          const errorResponse = await err.response.json();
          if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
            setOpenModal(true);
            setTopic("Members");
            setTenantName(tenant);
          } else {
            NotificationErrorSomethingWrong(errorResponse?.detail);
          }
        },
      }
    );
  //#endregion

  //#region  Remove member
  const { mutateAsync: onRemove, isLoading: onRemoveLoading } = useMutation(
    useCallback(async (id) => {
      const res = await apiSDK.tenantUserApi.deleteTenantUser({
        tenantUserId: id,
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant-user"]);
        notification.success({
          message: "Successfully",
          description: "successfully remove member from your tenant!",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region  Set Default Tenant
  const {
    mutateAsync: onSetDefaultTenant,
    isLoading: onSetDefaultTenantLoading,
  } = useMutation(
    useCallback(async (data) => {
      localStorage.setItem("tenant-name", data.tenantName);
      const res = await apiSDK.tenantApi.setDefaultTenant({
        tenantName: data.tenantName,
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant-user"]);
        notification.success({
          message: "Successfully",
          description: "successfully set new default tenant",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result;

  return {
    data: result,
    loading:
      isFetching ||
      onSetDefaultTenantLoading ||
      onRemoveLoading ||
      onAddMemberLoading,
    onAddMember,
    onRemove,
    onSetDefaultTenant,
    error,
  };
};
