// Libs
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Col, Divider, Input, Row, Switch } from "antd";
import { useParams } from "react-router-dom";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

// Hooks
import { useSDKTenants } from "hooks/tenants-sdk";
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";

function MainAddOnsItem(props) {
  const { tenant } = useParams();
  const {
    details,
    showPrice = true,
    showAction = true,
    onChange = () => {},
    defaultQuantity = 0,
  } = props;

  const {
    name: title,
    addonCode: code,
    price,
    type,
    unitName: unit,
    description,
  } = details;

  //#region Handle Quantity
  const [quantity, setQuantity] = useState(defaultQuantity);
  //#endregion

  //#region Tenants Hooks
  const { data: tenantDetails } = useSDKTenants({ tenantName: tenant });
  //#endregion

  //#region Subscriptions Hooks
  const { data: subscriptionDetails } = useZohoSubscriptions({
    subscriptionId: tenantDetails?.subscriptionId,
  });
  const currentAddonsDetails = subscriptionDetails?.addons?.find(
    (item) => item.code === code
  );
  //#endregion

  //#region Handle current quantity
  useEffect(() => {
    setQuantity(
      defaultQuantity > 0
        ? defaultQuantity
        : currentAddonsDetails?.quantity || 0
    );
  }, [currentAddonsDetails?.quantity, defaultQuantity]);

  const handleChange = (type) => {
    let newQuantity;
    if (type === "plus") {
      newQuantity = quantity + 1;
    }

    if (type === "minus" && quantity > 0) {
      newQuantity = quantity - 1;
    }

    setQuantity(newQuantity);
    handleUpdateAddons(newQuantity);
  };

  const handleUpdateAddons = (newQuantity) => {
    onChange({
      data: { code: code, quantity: newQuantity, name: title, price: price },
    });
  };
  //#endregion

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col className={s.featured} span={12}>
          <h3>{title}</h3>
          {showPrice && (
            <p className={s.volumePrice}>
              Monthly price:{" "}
              <strong>
                ${price} / {type === "volume" ? unit : "Month"}
              </strong>
            </p>
          )}
          <p>{description}</p>
          {type === "volume" && (
            <p className={s.featuredQtyStatus}>
              Current additional: {currentAddonsDetails?.quantity || 0} {unit}
            </p>
          )}
          {type !== "volume" && (
            <p className={s.featuredQtyStatus}>
              Current status:{" "}
              {currentAddonsDetails?.quantity > 0 ? "ENABLED" : "DISABLED"}
            </p>
          )}
        </Col>
        {showAction && (
          <Col className={s.featuredButton}>
            <Row justify="end" align="middle" gutter={[16, 0]}>
              <Col>
                {type === "volume" && (
                  <Col span={24} className={s.qtyContainer}>
                    <Row>
                      <Col
                        className={`${s.quantityActionButton} ${
                          s.minQuantity
                        } ${quantity === 0 ? "disabled" : ""}`}
                        onClick={() => handleChange("minus")}
                      >
                        <MinusOutlined />
                      </Col>
                      <Col>
                        <Input
                          className={s.quantityInput}
                          value={`${quantity} ${unit}`}
                          readOnly
                        />
                      </Col>

                      <Col
                        className={`${s.quantityActionButton} ${s.plusQuantity}`}
                        onClick={() => handleChange("plus")}
                      >
                        <PlusOutlined />
                      </Col>
                    </Row>
                  </Col>
                )}
                {type !== "volume" && (
                  <Switch
                    size="default"
                    onChange={(val) => {
                      handleUpdateAddons(+val);
                    }}
                  />
                )}
              </Col>
            </Row>
          </Col>
        )}

        <Divider />
      </Row>
    </>
  );
}

MainAddOnsItem.propTypes = {
  details: propTypes.object,
  showPrice: propTypes.bool,
  showAction: propTypes.bool,
  onChange: propTypes.func,
  defaultQuantity: propTypes.number,
};

export default MainAddOnsItem;
