import propTypes from "prop-types";
import { Select, Form } from "antd";
import { useSDKCredentialsAwsRegion } from "hooks/credentials-sdk";
const { Option } = Select;

export default function CompLibsSelectAwsRegion(props) {
  const { form, name, label } = props;

  const { data: regionList } = useSDKCredentialsAwsRegion();

  return (
    <>
      <Form form={form}>
        <Form.Item
          name={name}
          label={label}
          rules={[{ required: true, message: "Region is required!" }]}
        >
          <Select placeholder="Select Region" showSearch>
            {regionList?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.id}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
}

CompLibsSelectAwsRegion.propTypes = {
  form: propTypes.any,
  name: propTypes.string,
  label: propTypes.string,
};
