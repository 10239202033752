import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { message } from "antd";
import moment from "moment-timezone";
import { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import apiSDK from "utils/sdk-service";
import { useZohoSubscriptionCustomer } from "./zoho-integration-sdk";

export const useSDKAuth = () => {
  const [loading, setLoading] = useState(false);
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { onCreate: createZohoCustomer } = useZohoSubscriptionCustomer();

  const { 0: cookie, 1: setCookie } = useCookies();

  //? Handle Status
  const isAuth = cookie.access_token;

  //? Handle Remove Cookies on Logout
  const authorizationLogout = useCallback(
    (redirectUri) => {
      logout({
        logoutParams: {
          returnTo: redirectUri
            ? `${window.location.origin}${redirectUri}`
            : `${window.location.origin}`,
        },
      });
      setCookie("access_token", "", {
        path: "/",
      });

      setCookie("zoho_token", "", {
        path: "/",
      });

      localStorage.removeItem("tenant-name");
    },
    [logout, setCookie]
  );

  //? Handle Generate Token to Cookies
  const authorizationLogin = useCallback(async () => {
    try {
      if (user) {
        setLoading(true);
        const token = await getAccessTokenSilently();

        if (token) {
          const today = new Date();
          const nextTenYear = new Date();
          nextTenYear.setDate(today.getDate() + 1);

          setCookie("access_token", token, {
            expires: nextTenYear,
            path: "/",
          });

          window.location.reload();
        }
      }
    } catch (error) {
      message.error("Login session expired!");
      authorizationLogout();
    } finally {
      setLoading(false);
    }
  }, [authorizationLogout, getAccessTokenSilently, setCookie, user]);

  const { mutateAsync: authorizationEvent } = useMutation(
    useCallback(async () => {
      const zone = moment.tz.guess();
      const offset = moment.tz(zone).format("Z");
      if (user && isAuth) {
        const res = await apiSDK.tenantUserApi.getOrCreateDefaultTenantUser({
          v1Auth0DefaultTenantUserForm: {
            email: user?.email,
            userId: user?.sub,
            timezone: `${zone} (GMT ${offset})`,
          },
        });
        const defaultTenant = res?.result?.user?.tenants?.find(
          (item) => item.defaultTenant
        );

        const currentTenantName = localStorage.getItem("tenant-name");
        if (!currentTenantName) {
          localStorage.setItem("tenant-name", defaultTenant.tenantName);
        }

        await createZohoCustomer({
          email: user?.email,
          tenantName: defaultTenant?.tenantName,
        });

        return {
          email: user?.email,
          tenantName: defaultTenant.tenantName,
          tenantId: defaultTenant.tenantId,
          externalUserId: user?.sub,
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, user]),
    {
      onSuccess: () => {
        message.success("Success login");
      },
      onError: () => {
        message.error("Something wrong when login");
        authorizationLogout();
      },
    }
  );

  return {
    isAuth,
    loading,
    authorizationLogin,
    authorizationLogout,
    authorizationEvent,
  };
};
