// Libs
import React from "react";

// Icons
import {
  DesktopOutlined,
  SettingOutlined,
  AppstoreOutlined,
  KeyOutlined,
  LockOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";

function DashboardMenu() {
  const dashboardMenu = [
    {
      label: "Dashboard",
      link: `./`,
      key: `dashboard`,
      icon: <AppstoreOutlined />,
      dataTestId: "dashboard-side-menu-home",
    },
    {
      label: "Credentials",
      link: `./credentials`,
      key: `credentials`,
      icon: <KeyOutlined />,
      dataTestId: "dashboard-side-menu-credentials",
    },
    {
      label: "Devices",
      link: `./devices`,
      key: `devices`,
      icon: <DesktopOutlined />,
      dataTestId: "dashboard-side-menu-devices",
    },
    {
      label: "Firewalls",
      link: `./firewalls`,
      key: `firewalls`,
      icon: <LockOutlined />,
      dataTestId: "dashboard-side-menu-firewalls",
    },
    {
      label: "Manage Link",
      link: `./manage-link`,
      key: `manage-link`,
      icon: <DeploymentUnitOutlined />,
      dataTestId: "dashboard-side-menu-manage-link",
    },
    {
      label: "Settings",
      link: `./settings`,
      key: `settings`,
      icon: <SettingOutlined />,
      dataTestId: "dashboard-side-menu-settings",
    },
  ];

  return dashboardMenu;
}

export default DashboardMenu;
