// Libs
import React, { useCallback, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

// Components
import MainLoading from "components/libs/loading";

// Hooks
import { useSDKAuth } from "hooks/auth-sdk";
import useQueryString from "hooks/useQueryString";
import { getConfig } from "config";

function LoginRedirectPage() {
  const navigate = useNavigate();
  const query = useQueryString();

  const openAgent = query.get("open-agent");
  const showToken = query.get("show-token");
  const target = query.get("target");
  const action = query.get("actions");
  const params = query.get("params");

  const config = getConfig();

  //#region  Auth Hook
  const { authorizationLogin, authorizationEvent, isAuth } = useSDKAuth();
  //#endregion

  //#region  Handle redirect after login
  const redirectToDashboard = useCallback(async () => {
    const { tenantName, tenantId, externalUserId } = await authorizationEvent();
    const currentTenantName = localStorage.getItem("tenant-name") || tenantName;

    if (openAgent) {
      window.location.href = `agent-ae://login?tenantName=${currentTenantName}&tenantId=${tenantId}&externalUserId=${externalUserId}`;
    }

    if (config.devMode === "true" && showToken) {
      navigate(`show-token`);
      return;
    }

    if (target && action && params) {
      navigate(
        `/dashboard/${currentTenantName}/${target}?actions=${action}&params=${params}`
      );
      return;
    }

    navigate(`/dashboard/${currentTenantName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    action,
    authorizationEvent,
    config.devMode,
    openAgent,
    params,
    showToken,
    target,
  ]);

  //#region  Handle Redirect to Default Tenant
  useEffect(() => {
    if (!isAuth) {
      authorizationLogin();
    } else {
      redirectToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  return (
    <>
      <MainLoading />
    </>
  );
}

export default withAuthenticationRequired(LoginRedirectPage, {
  onRedirecting: () => <MainLoading />,
});
