// Libs
import React from "react";
import { useNavigate } from "react-router-dom";

// Styles
import s from "./index.module.scss";

// Icons
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

// Hooks
import { useSDKAuth } from "hooks/auth-sdk";

function DashboardHeaderDropdown() {
  const navigate = useNavigate();

  //#region Handle Logout
  const { authorizationLogout } = useSDKAuth();
  //#endregion

  const item = [
    {
      key: "1",
      label: (
        <div className={s.dropdownMenu} onClick={() => navigate(`profile`)}>
          <UserOutlined />
          Profile
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <div className={s.dropdownMenu} onClick={() => navigate(`settings`)}>
            <SettingOutlined />
            Setting
          </div>
        </>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <div className={s.dropdownMenu} onClick={() => authorizationLogout()}>
          <LogoutOutlined />
          Logout
        </div>
      ),
    },
  ];

  return item;
}

export default DashboardHeaderDropdown;
