import React, { useState } from "react";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import propTypes from "prop-types";
import { useSDKUsers } from "hooks/user-sdk";
import { useSDKDevices } from "hooks/device-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

export default function ModalSharedDevice(props) {
  const { visible, handleClose, deviceId } = props;
  const { user } = useAuth0();
  const params = useParams();
  const currentTenant = params.tenant;

  //#region  Hook list
  const { data: userData } = useSDKUsers({ externalUserId: user?.sub });
  const { data: deviceList, onShared } = useSDKDevices({
    tenantName: currentTenant,
  });
  //#endregion

  //#region  Default data
  const list = userData?.tenants?.map((item) => {
    return {
      label: item.displayName,
      value: item.tenantId,
    };
  });

  const currentSelectedTenant = userData?.tenants?.find(
    (item) => item.tenantName === currentTenant
  )?.tenantId;

  const selectedDevice =
    deviceId && deviceList?.find((item) => item.id === deviceId);
  //#endregion

  //#region  Handle select tenant
  const [selectedTenant, setSelectedTenant] = useState();
  const handleSelectTenant = (value) => {
    setSelectedTenant(value);
  };
  //#endregion

  //#region  Handle share to other user
  const [otherUser, setOtherUser] = useState();
  const handleSetOtherUser = (e) => {
    const value = e.target.value;
    setOtherUser(value);
  };
  //#endregion

  //#region  Handle select mode
  const [isInternal, setIsInternal] = useState(true);
  const handleChangeMode = () => {
    setIsInternal(!isInternal);
  };
  //#endregion

  //#region  Handle transfer
  const handleShare = async () => {
    const result = await onShared({ tenant: selectedTenant, user: otherUser });
    if (result) {
      handleClose();
    }
  };
  //#endregion

  return (
    <>
      <Modal
        title="Share Device"
        open={visible}
        footer={false}
        onCancel={handleClose}
      >
        <>
          <div>
            <div>
              <p>
                Device:{" "}
                <strong>
                  {selectedDevice?.clientUUID || selectedDevice?.hostName}
                </strong>
              </p>

              {isInternal ? (
                <>
                  <p>
                    Share this device to other tenant, please specify tenant.{" "}
                    <br />
                    Select Tenant:
                  </p>
                  <Select
                    options={list}
                    defaultValue={currentSelectedTenant}
                    style={{ width: "100%" }}
                    onChange={handleSelectTenant}
                  />
                </>
              ) : (
                ""
              )}

              {!isInternal ? (
                <>
                  <p>
                    Share this device to other user, please input user email /
                    user ID. <br />
                    Email / ID
                  </p>
                  <Input
                    onChange={handleSetOtherUser}
                    placeholder="Email / User ID"
                  />
                </>
              ) : (
                ""
              )}

              {isInternal ? (
                <p onClick={handleChangeMode} style={{ marginTop: 10 }}>
                  To share this device with another user,{" "}
                  <Button type="link" style={{ padding: 0 }}>
                    click here.
                  </Button>
                </p>
              ) : (
                ""
              )}

              {!isInternal ? (
                <p onClick={handleChangeMode} style={{ marginTop: 10 }}>
                  To share this device with another tenant,{" "}
                  <Button type="link" style={{ padding: 0 }}>
                    click here.
                  </Button>
                </p>
              ) : (
                ""
              )}
            </div>
            <div style={{ marginTop: 30 }}>
              <Row justify="end" gutter={[16, 0]}>
                <Col>
                  <Button onClick={handleClose}>Cancel</Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={handleShare}>
                    Share
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

ModalSharedDevice.propTypes = {
  visible: propTypes.bool,
  handleClose: propTypes.func,
  deviceId: propTypes.number,
};
