import React, { useState } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import propTypes from "prop-types";
import { useSDKUsers } from "hooks/user-sdk";
import { useSDKDevices } from "hooks/device-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

export default function ModalTransferDevice(props) {
  const { visible, handleClose, deviceId } = props;
  const { user } = useAuth0();
  const params = useParams();
  const currentTenant = params.tenant;

  //#region  Hook list
  const { data: userData } = useSDKUsers({ externalUserId: user?.sub });
  const { data: deviceList, onTransfer } = useSDKDevices({
    tenantName: currentTenant,
  });
  //#endregion

  //#region  Default data
  const list = userData?.tenants?.map((item) => {
    return {
      label: item.displayName,
      value: item.tenantId,
    };
  });

  const currentSelectedTenant = userData?.tenants?.find(
    (item) => item.tenantName === currentTenant
  )?.tenantId;

  const selectedDevice =
    deviceId && deviceList?.find((item) => item.id === deviceId);
  //#endregion

  //#region  Handle select tenant
  const [selectedTenant, setSelectedTenant] = useState();
  const handleSelectTenant = (value) => {
    setSelectedTenant(value);
  };
  //#endregion

  //#region  Handle transfer
  const handleTransfer = async () => {
    try {
      await onTransfer({ id: deviceId, tenantId: selectedTenant });
      handleClose();
    } catch (error) {
      return;
    }
  };
  //#endregion

  return (
    <>
      <Modal
        title="Transfer Device"
        open={visible}
        footer={false}
        onCancel={handleClose}
      >
        <>
          <div>
            <div>
              <p>
                Device:{" "}
                <strong>
                  {selectedDevice?.clientUUID || selectedDevice?.hostName}
                </strong>
              </p>
              <p>
                Transfer device to other tenant, please specify tenant. <br />
                Select Tenant:
              </p>
              <Select
                options={list}
                defaultValue={currentSelectedTenant}
                style={{ width: "100%" }}
                onChange={handleSelectTenant}
              />
            </div>
            <div style={{ marginTop: 30 }}>
              <Row justify="end" gutter={[16, 0]}>
                <Col>
                  <Button onClick={handleClose}>Cancel</Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={handleTransfer}>
                    Transfer
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

ModalTransferDevice.propTypes = {
  visible: propTypes.bool,
  handleClose: propTypes.func,
  deviceId: propTypes.number,
};
