import { Badge } from "antd";
import s from "./index.module.scss";

export default function FirewallUtilsStatus() {
  //#region Status List
  const statusList = [
    {
      key: "ACTIVE",
      label: "Available",
      color: "processing",
    },
    {
      key: "LINKED",
      label: "Linked",
      color: "success",
    },
    {
      key: "INACTIVE",
      label: "Inactive",
      color: "default",
    },
  ];
  //#endregion

  return {
    title: "Status",
    dataIndex: "status",
    key: "status",
    filters: statusList.map((item) => {
      return { text: item.label, value: item.key };
    }),
    onFilter: (value, record) => {
      return record.status === value;
    },
    render: (t, r) => {
      const currentStatus = statusList.find((item) => item.key === t) || {
        ...statusList.find((item) => item.key === "INACTIVE"),
        label: t,
      };
      return (
        <>
          <div className={s.status}>
            <Badge status={currentStatus.color} text={currentStatus.label} />
          </div>
        </>
      );
    },
  };
}
