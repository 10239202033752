// Libs
import React from "react";
import { Col, Divider, Row } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// Components
import MainButton from "components/libs/button";
import { Container } from "reactstrap";

// Assets
import { footerFirstMenu, footerSecondMenu } from "data/footer";
import s from "./index.module.scss";
import { FacebookLogo } from "assets/icons/facebook-logo";
import { TwitterLogo } from "assets/icons/twitter-logo";
import { WhatsappLogo } from "assets/icons/whatsapp-logo";

export default function MainFooterV2() {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ marginTop: 80 }}>
        <svg
          width="100%"
          viewBox="0 0 1280.000000 97.000000"
          preserveAspectRatio="none"
        >
          <g
            transform="translate(0.000000,97.000000) scale(0.100000,-0.100000)"
            fill="#005789"
            stroke="none"
          >
            <path d="M12465 895 c-767 -136 -1280 -224 -1755 -299 -1998 -318 -3644 -476 -4950 -476 -716 0 -1355 36 -2170 121 -637 67 -1064 124 -2320 313 -632 95 -1162 166 -1242 166 l-28 0 0 -360 0 -360 6400 0 6400 0 0 475 c0 446 -1 475 -17 474 -10 0 -153 -25 -318 -54z"></path>
          </g>
        </svg>
        <footer className={s.footer_container}>
          <Container style={{ padding: 0 }}>
            <Col className={s.content_container}>
              <Row>
                <Col span={8}>
                  <Col
                    span={24}
                    style={{ marginBottom: 20 }}
                    className={s.logo}
                  >
                    <img src="logo.png" alt="footer-logo" />
                  </Col>
                  <Col span={24}>
                    <p>
                      Simplify your network security operations with our
                      cutting-edge cloud-based solution for firewall and device
                      rule management. Easily manage firewall and device rules
                      from a single, intuitive dashboard with AllowExactly.
                    </p>
                  </Col>
                </Col>
                <Col span={4} className={s.menu_list}>
                  <Divider className={s.divider} />
                  {footerFirstMenu.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        span={24}
                        className={s.menu_item}
                        onClick={() => {
                          navigate(item.link);
                        }}
                      >
                        {item.label}
                      </Col>
                    );
                  })}
                </Col>
                <Col span={4} className={s.menu_list}>
                  <Divider className={s.divider} />
                  {footerSecondMenu.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        span={24}
                        className={s.menu_item}
                        onClick={() => {
                          navigate(item.link);
                        }}
                      >
                        {item.label}
                      </Col>
                    );
                  })}
                </Col>
                <Col span={6} className={s.download_btn}>
                  <MainButton onClick={() => navigate("#download")}>
                    Download Now
                  </MainButton>
                </Col>
              </Row>
              <Col span={24}>
                <Divider className={s.divider} />
                <Col span={4} className={s.social_icon_container}>
                  <Row gutter={[16, 0]} justify="center" align="middle">
                    <Col className={s.social_icon}>
                      <WhatsappLogo />
                    </Col>
                    <Col className={s.social_icon}>
                      <FacebookLogo />
                    </Col>
                    <Col className={s.social_icon}>
                      <TwitterLogo />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                  <p style={{ marginBottom: 0 }}>
                    © Copyright {moment().year()} AllowExactly. All rights
                    reserved.
                  </p>
                </Col>
              </Col>
            </Col>
          </Container>
        </footer>
      </div>
    </>
  );
}
