import propTypes from "prop-types";
import { Card, Col, Divider, Row, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import MainButton from "components/libs/button";
import s from "../index.module.scss";
import { useEffect, useState } from "react";

export default function DashboardCheckoutSummary(props) {
  const {
    onCheckout = () => {},
    addonsList = [],
    total = 0,
    currentPlan = "",
    selectedPlan = "",
    currentAddonsList = [],
  } = props;

  //#region Handle check addons changes
  const [isAddNewAddons, setIsAddNewAddons] = useState(false);
  useEffect(() => {
    const checkAddons = addonsList.filter((item) => {
      const checkAddons = !currentAddonsList.find(
        (target) =>
          target.code === item.code && target.quantity === item.quantity
      );
      return checkAddons;
    });

    if (checkAddons?.length > 0) {
      setIsAddNewAddons(true);
    } else {
      setIsAddNewAddons(false);
    }
  }, [addonsList, currentAddonsList]);

  //#endregion

  //#region Handle show current plan
  const [isShowCurrentPlan, setIsShowCurrentPlan] = useState(false);
  useEffect(() => {
    if (currentPlan.planCode !== selectedPlan.planCode) {
      setIsShowCurrentPlan(true);
    } else {
      setIsShowCurrentPlan(false);
    }
  }, [currentPlan.planCode, selectedPlan.planCode]);
  //#endregion

  //#region Selected Plan Interval
  const selectedPlanInterval =
    selectedPlan?.intervalUnit === "months" ? "month" : "year";
  //#endregion

  const ItemDetails = ({ title, status }) => {
    return (
      <>
        <Row align={"middle"} justify={"space-between"}>
          <Col span={14}>{title}</Col>
          <Col
            span={10}
            style={{
              alignSelf: "baseline",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {status}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Card>
        <div>
          <strong>Plan:</strong>
          <div>
            <ul>
              {selectedPlan && isShowCurrentPlan && (
                <>
                  <li className={s.currentPlan}>
                    Current Plan: {currentPlan.name}
                  </li>
                  <li>
                    <ItemDetails
                      title={`${"New Plan"}: ${selectedPlan?.name}`}
                      status={`$${selectedPlan?.recurringPrice?.toFixed(
                        2
                      )} / ${selectedPlanInterval}`}
                    />
                  </li>
                </>
              )}
              {(!selectedPlan || !isShowCurrentPlan) && (
                <li>
                  <ItemDetails
                    title={`${"Current Plan"}: ${currentPlan?.name}`}
                    status={`$${currentPlan?.recurringPrice?.toFixed(2)} / ${
                      currentPlan?.intervalUnit === "months" ? "month" : "year"
                    }`}
                  />
                </li>
              )}
            </ul>
          </div>
        </div>
        {addonsList.length > 0 && (
          <div>
            <strong>Add-ons:</strong>
            <div>
              <ul>
                {addonsList?.map((item) => {
                  const total = (
                    item.quantity *
                    item.price *
                    (selectedPlan.intervalUnit === "months" ? 1 : 12)
                  ).toFixed(2);
                  return (
                    <li>
                      <ItemDetails
                        title={`${item.name} (x${item.quantity})`}
                        status={`$${total} ${
                          selectedPlan.intervalUnit === "months"
                            ? "/ month"
                            : "/ year"
                        }`}
                      />
                      {selectedPlan.intervalUnit === "years" && (
                        <div
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            marginBottom: 10,
                          }}
                        >
                          price: ${(item.quantity * item.price).toFixed(2)} x 12
                          month
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <Divider />
        <div>
          <strong>
            <ItemDetails
              title={
                <div>
                  Total{" "}
                  <span>
                    <Tooltip title="This total reflects adjustments for the current billing period. Please proceed to checkout to see the actual charge.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                </div>
              }
              status={`$${total.toFixed(2)} / ${selectedPlanInterval}`}
            />
          </strong>
        </div>
        <div style={{ marginTop: 30, width: "100%" }}>
          <MainButton
            customClass={s.checkoutButton}
            onClick={onCheckout}
            disabled={!isShowCurrentPlan && !isAddNewAddons}
          >
            Checkout Now!
          </MainButton>
        </div>
      </Card>
    </>
  );
}

DashboardCheckoutSummary.propTypes = {
  onCheckout: propTypes.func,
  addonsList: propTypes.array,
  total: propTypes.number,
  currentPlan: propTypes.object,
  selectedPlan: propTypes.object,
  currentAddonsList: propTypes.array,
};
