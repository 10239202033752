import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";
import { usePlanSuggestion } from "context/plan-suggestion";

export const useSDKFirewallDestination = ({ limit, page, tenantName }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const navigate = useNavigate();

  const { setOpenModal, setTopic, setTenantName } = usePlanSuggestion();

  //#region  Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["firewall-destinations"],
    queryFn: async () => {
      const res = await apiSDK.tenantApi.getFirewallDestinationsByTenantName({
        tenantName: tenantName,
      });
      return res;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });
  //#endregion

  //#region  Add Firewall Destination
  const { mutateAsync: onAdd, isLoading: onAddLoading } = useMutation(
    useCallback(
      async (payload) => {
        const res = await apiSDK.firewallDestinationApi.addFirewallDestination({
          v1CreateFirewallDestinationForm: {
            ...payload,
            credentialName: payload?.credentialName,
            firewallName: payload?.firewallName,
            placeholderIpAddress: payload?.placeholderIpAddress,
            projectId: payload?.projectId,
            tenantName: tenantName,
            updateMode: payload?.updateMode,
            firewallIpVersion: payload?.firewallIpVersion,
            direction: payload?.direction,
          },
        });
        return res;
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["firewall-destinations"]);
        notification.success({
          message: "Successfully",
          description: "successfully add new firewall destination",
        });

        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Firewall Destination");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //#region  Update Firewall Destination
  const { mutateAsync: onUpdate, isLoading: onUpdateLoading } = useMutation(
    useCallback(
      async ({ id, submission }) => {
        console.log("update hooks");
        const res =
          await apiSDK.firewallDestinationApi.updateFirewallDestination({
            firewallDestinationId: id,
            v1UpdateFirewallDestinationForm: {
              ...submission,
              credentialName: submission?.credentialName,
              firewallIpVersion: submission?.firewallIpVersion,
              firewallName: submission?.firewallName,
              placeholderIpAddress: submission?.placeholderIpAddress,
              projectId: submission?.projectId,
              tenantName: tenantName,
              updateMode: submission?.updateMode,
              vpcName: submission?.vpcName,
              direction: submission?.direction,
            },
          });
        return res;
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["firewall-destinations"]);
        notification.success({
          message: "Successfully",
          description: "successfully update firewall destination",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region  Delete Credentials
  const { mutateAsync: onDelete, isLoading: onDeleteLoading } = useMutation(
    useCallback(async (id) => {
      const res = await apiSDK.firewallDestinationApi.deleteFirewallDestination(
        {
          firewallDestinationId: id,
        }
      );
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["firewall-destinations"]);
        notification.success({
          message: "Successfully",
          description: "successfully delete selected firewall destination",
        });

        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    onAdd,
    onUpdate,
    onDelete,
    error,
    loading: isFetching || onAddLoading || onUpdateLoading || onDeleteLoading,
  };
};

export const useSDKFirewallDestinationHistory = ({
  tenantName,
  id,
  timezone,
}) => {
  const { tenant } = useParams();
  const navigate = useNavigate();

  //? Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["devices"],
    queryFn: async () => {
      const res = await apiSDK.tenantApi.getFirewallDestinationHistory({
        firewallDestinationId: id,
        tenantName: tenantName,
        timezone: timezone,
      });
      return res;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
    error,
  };
};
