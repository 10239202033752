// Libs
import React, { useEffect, useState } from "react";
import { ConfigProvider, Layout } from "antd";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Styles
import s from "./index.module.scss";

// Components
import DashboardLayoutSider from "./sider";
import DashboardLayoutHeader from "./header";
import BetaBanner from "components/libs/beta-banner";
import moment from "moment-timezone";
import { useSDKUsers } from "hooks/user-sdk";
import { useAuth0 } from "@auth0/auth0-react";

const { Content, Footer } = Layout;

function DashboardLayout(props) {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(false);

  //#region Handle accepted tenant
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuth0();

  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });

  const findTenant =
    userDetails?.tenants?.find(
      (tenant) => tenant.tenantName === params.tenant
    ) || "not-found";

  useEffect(() => {
    if (userDetails && findTenant === "not-found") {
      navigate("/not-found");
    }
  }, [findTenant, navigate, userDetails]);
  //#endregion

  return (
    <>
      <ConfigProvider theme={{ token: { colorPrimary: "#ecc30e" } }}>
        <Layout style={{ minHeight: "100vh" }} hasSider>
          <DashboardLayoutSider
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Layout
            className={`site-layout ${s.layout} ${
              collapsed && s.layoutCollapsed
            }`}
          >
            <DashboardLayoutHeader />
            <Content
              style={{
                margin: "24px 16px 0",
                overflow: "initial",
              }}
            >
              <Outlet>{children}</Outlet>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              © Copyright {moment().year()} AllowExactly. All rights reserved.
            </Footer>
            <BetaBanner />
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
}

export default DashboardLayout;
