import { Card, Col, Row, Skeleton } from "antd";
import React from "react";
import propTypes from "prop-types";

// Styles
import s from "./index.module.scss";

// Icons
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function QuickstartActionItem(props) {
  const {
    icon,
    title,
    description,
    link,
    ctaText,
    ctaAction = () => {},
    isDisabled = false,
    loading = false,
  } = props;
  const navigate = useNavigate();
  return (
    <>
      <Card
        className={`${s.card} ${isDisabled ? "disabled" : ""}`}
        onClick={() => (link ? navigate(link) : ctaAction())}
      >
        {loading && <Skeleton />}
        {!loading && (
          <Row>
            <Col span={3} className={s.iconContainer}>
              <div className={s.icon}>{icon}</div>
            </Col>
            <Col span={15} className={s.detailsContainer}>
              <h3 className={s.title}>{title}</h3>
              <p className={s.description}>{description}</p>
            </Col>
            <Col span={6} className={s.ctaContainer}>
              <p className={s.cta}>
                {ctaText || "Start Now"}
                <span>
                  <ArrowRightOutlined />
                </span>
              </p>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
}

QuickstartActionItem.propTypes = {
  icon: propTypes.node,
  title: propTypes.string,
  description: propTypes.string,
  link: propTypes.string,
  ctaText: propTypes.string,
  ctaAction: propTypes.func,
  isDisabled: propTypes.bool,
  loading: propTypes.bool,
};

export default QuickstartActionItem;
