// Libs
import React, { useEffect, useState } from "react";
import { Col, Empty, Row, Select, Spin, Table } from "antd";
import { useLocation, useParams } from "react-router-dom";

// Hooks
import { useSDKDevices } from "hooks/device-sdk";
import { useSDKFirewallDestination } from "hooks/firewall-destination-sdk";
import { useSDKManageLink } from "hooks/manage-link-sdk";

// Components
import DeviceDetails from "components/libs/device-details";
import FirewallDestinationDetails from "components/libs/firewall-details";
import { DeviceManageLinkColumns } from "../device-manage-link/utils";
import { FirewallDeviceManageLinkColumns } from "../firewall-manage-link/utils";
import PageHeader from "components/libs/page-header";

// Helpers
import queryStringParser from "helper/queryStringParse";

// Styles
import s from "./index.module.scss";

function DashboardContentsManageLink() {
  const params = useParams();
  const location = useLocation();
  const search = queryStringParser(location.search);
  const tenantName = params.tenant;

  //? Handle Initial with Query String
  const initialType = search.get("type");
  const initialId = search.get("id");

  //#region Handle Select Type
  const [selectedType, setSelectedType] = useState("DEVICE");
  const handleSelectType = (value) => {
    setSelectedType(value);
    setSelectToManage();
  };
  //#endregion

  //#region Handle Select To Manage
  const [selectToManage, setSelectToManage] = useState();
  const handleSelectToManage = (value) => {
    setSelectToManage(value);
  };
  //#endregion

  //#region Manage Link Hooks
  const { data, loading, onLink, onUnlink } = useSDKManageLink({
    tenantName: tenantName,
    id: selectToManage,
    timezone: "",
    type: selectedType,
  });
  //#endregion

  //#region Device Hooks
  const { data: deviceList, loading: deviceListLoading } = useSDKDevices({
    tenantName: tenantName,
  });
  //#endregion

  //#region Firewall Hooks
  const { data: firewallList, loading: firewallListLoading } =
    useSDKFirewallDestination({
      tenantName: tenantName,
    });
  //#endregion

  //#region Handle Column
  //? Manage Device Column
  const deviceSelectedColumn = DeviceManageLinkColumns({
    status: "selected",
    onLink: (id) =>
      onUnlink({ firewallDestinationId: id, deviceId: selectToManage }),
  });
  const deviceAvailableColumn = DeviceManageLinkColumns({
    status: "available",
    onLink: (id) =>
      onLink({ firewallDestinationId: id, deviceId: selectToManage }),
  });
  //? Manage Firewall Column
  const firewallSelectedColumns = FirewallDeviceManageLinkColumns({
    status: "selected",
    onLink: (id) =>
      onUnlink({ deviceId: id, firewallDestinationId: selectToManage }),
  });
  const firewallAvailableColumns = FirewallDeviceManageLinkColumns({
    status: "available",
    onLink: (id) =>
      onLink({ deviceId: id, firewallDestinationId: selectToManage }),
  });
  //#endregion

  //#region Initial Value
  useEffect(() => {
    if (initialType) {
      setSelectedType(initialType.toUpperCase());
    }

    if (initialId) {
      setSelectToManage(+initialId);
    }
  }, [initialId, initialType]);
  //#endregion

  return (
    <>
      <PageHeader title="Manage Link" className={s.header} />
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Select
            options={[
              { label: "Manage Device Link", value: "DEVICE" },
              { label: "Manage Firewall Link", value: "FIREWALL" },
            ]}
            onChange={handleSelectType}
            style={{ width: "100%" }}
            value={selectedType}
            placeholder="Select manage link by ..."
          />
        </Col>
        <Col span={8}>
          <Select
            options={
              selectedType === "DEVICE"
                ? deviceList.map((item) => {
                    return {
                      label: item.hostName ? item.hostName : item.clientUUID,
                      value: item.id,
                    };
                  })
                : firewallList.map((item) => {
                    return {
                      label: `${item.firewallRuleName} (${item.cloudId})`,
                      value: item.firewallDestinationId,
                    };
                  })
            }
            style={{ width: "100%" }}
            placeholder={`Select ${
              selectedType === "DEVICE" ? "Device" : "Firewall Destination"
            }`}
            onChange={handleSelectToManage}
            value={
              deviceListLoading || firewallListLoading ? null : selectToManage
            }
            disabled={deviceListLoading || firewallListLoading ? true : false}
            notFoundContent={
              <>
                <Spin
                  spinning={
                    selectedType === "DEVICE"
                      ? deviceListLoading
                      : firewallListLoading
                  }
                >
                  <Empty />
                </Spin>
              </>
            }
          />
        </Col>
      </Row>

      <section style={{ marginTop: 40 }}>
        {selectedType === "DEVICE" && (
          <DeviceDetails
            deviceDetails={data}
            loading={selectToManage ? loading : false}
          />
        )}
        {selectedType === "FIREWALL" && (
          <FirewallDestinationDetails
            firewallDetails={data}
            loading={selectToManage ? loading : false}
          />
        )}
      </section>

      <section style={{ marginTop: 60 }}>
        {selectedType === "DEVICE" && (
          <Row gutter={[16, 32]}>
            <Col span={24}>
              <h3 className={s.title}>Selected Linked</h3>
              <Table
                columns={deviceSelectedColumn}
                dataSource={data.selectedFirewallDestinations}
                scroll={{ x: 1200 }}
                tableLayout="auto"
                loading={selectToManage ? loading : false}
                pagination={{ simple: true }}
              />
            </Col>
            <Col span={24}>
              <h3 className={s.title}>Available Link</h3>
              <Table
                columns={deviceAvailableColumn}
                dataSource={data.availableFirewallDestinations}
                scroll={{ x: 1200 }}
                tableLayout="auto"
                loading={selectToManage ? loading : false}
                pagination={{ simple: true }}
              />
            </Col>
          </Row>
        )}

        {selectedType === "FIREWALL" && (
          <Row gutter={[16, 32]}>
            <Col span={24}>
              <h3 className={s.title}>Selected Linked</h3>
              <Table
                columns={firewallSelectedColumns}
                dataSource={data.selectedDevices}
                scroll={{ x: 1200 }}
                tableLayout="auto"
                loading={selectToManage ? loading : false}
                pagination={{ simple: true }}
              />
            </Col>
            <Col span={24}>
              <h3 className={s.title}>Available Link</h3>
              <Table
                columns={firewallAvailableColumns}
                dataSource={data.availableDevices}
                scroll={{ x: 1200 }}
                tableLayout="auto"
                loading={selectToManage ? loading : false}
                pagination={{ simple: true }}
              />
            </Col>
          </Row>
        )}
      </section>
    </>
  );
}

export default DashboardContentsManageLink;
