// Libs
import React, { useMemo, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { Spin, Tooltip, Skeleton } from "antd";

// Components
import MainWaveDivider from "components/libs/wave-divider";
import PricingIconFree from "components/libs/pricing-icon/free-icon";
import PricingIconAdvance from "components/libs/pricing-icon/advance-icon";
import PricingIconPro from "components/libs/pricing-icon/pro-icon";
import MainButtonPrice from "components/libs/button-price";

// Initial Data
import { pricingContents } from "data/homepage";

// Hooks
import {
  useZohoSubscriptions,
  useZohoSubscriptionsPlans,
} from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";
import { useSDKUsers } from "hooks/user-sdk";
import { useSDKTenants } from "hooks/tenants-sdk";
import { useNavigate } from "react-router-dom";

function HomepageContentsPricingSection() {
  const navigate = useNavigate();

  //#region User Session
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  //#endregion

  //#region Handle Loading
  const [loading, setLoading] = useState(false);
  //#endregion

  //#region User Hook
  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });
  const defaultTenant = userDetails?.tenants?.find(
    (item) => item.defaultTenant
  );
  const trialAvailable = useMemo(() => {
    return userDetails?.trialAvailable;
  }, [userDetails?.trialAvailable]);
  //#endregion

  //#region Tenants Hook
  const { data: tenantDetails, loading: tenantLoading } = useSDKTenants({
    tenantName: defaultTenant?.tenantName,
  });
  const currentPlan = tenantDetails?.plan || "free";
  //#endregion

  //#region Zoho Checkout Hook
  const { onCreateSubscription } = useZohoSubscriptions({
    subscriptionId: tenantDetails?.subscriptionId || "",
  });
  //#endregion

  //#region Payment Hook
  const handleSubscribe = async (mode) => {
    const planCode = isMonthly ? mode.monthly_plan_code : mode.annual_plan_code;
    setLoading(true);

    if (!isAuthenticated && mode.name !== "Free") {
      loginWithRedirect({
        authorizationParams: {
          redirectUri: `${window.location.origin}/payment-redirect?planCode=${planCode}&mode=signup`,
          screen_hint: "signup",
        },
      });
      return;
    }

    if (!isAuthenticated && mode.name === "Free") {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
          redirect_uri: `${window.location.origin}/profile?mode=signup`,
        },
      });
      return;
    }

    if (!tenantDetails?.subscriptionId) {
      const submission = {
        email: user?.email,
        planCode: planCode,
        tenantName: defaultTenant.tenantName,
        customerId: defaultTenant?.customerId,
        excludeTrial: !(trialAvailable > 0),
      };
      onCreateSubscription(submission);
      return;
    }

    if (tenantDetails?.subscriptionId) {
      navigate(
        `/dashboard/${defaultTenant?.tenantName}/checkout?planCode=${planCode}`
      );
      return;
    }
  };
  //#endregion

  //#region Handle Monthly
  const [isMonthly, setIsMonthly] = useState(true);
  //#endregion

  //#region  Zoho Plans hooks
  const { data: zohoPlansDetails, loading: zohoPlansDetailsLoading } =
    useZohoSubscriptionsPlans();
  //#endregion

  return (
    <section>
      <MainWaveDivider scrollId="pricingPlans" title="Pricing Plans" />
      <Spin spinning={user ? tenantLoading : false}>
        <div>
          <h2 className={s.priceHeader1}>Choose a Plan</h2>
          <h3 className={s.priceHeader2}>AllowExactly Free vs Paid Versions</h3>

          <ButtonGroup className={s.pricePlanButton}>
            <Button
              color="primary"
              active={isMonthly}
              onClick={() => {
                setIsMonthly(true);
              }}
            >
              Monthly
            </Button>
            <Button
              color="primary"
              active={!isMonthly}
              onClick={() => {
                setIsMonthly(false);
              }}
            >
              Annually
            </Button>
          </ButtonGroup>
          <Spin style={{ maxHeight: "100%" }} spinning={loading}>
            <div className={s.priceMain}>
              <table className={s.priceTable}>
                <tbody>
                  <tr>
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTablePopular}>Recommended</td>
                    <td className={s.priceTableBlank} />
                  </tr>
                  <tr className={s.priceTableHead}>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td key={mode.name}>
                          {mode.displayName}
                          <br />
                          <small className={s.priceSubHead}>
                            {mode.subHead}
                          </small>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td className={s.priceLabel} key={mode.name}>
                          {mode.name === "Free" ? (
                            <PricingIconFree />
                          ) : mode.name === "Advanced" ? (
                            <PricingIconAdvance />
                          ) : (
                            <PricingIconPro />
                          )}
                          <br />
                          {zohoPlansDetailsLoading ? (
                            <div className={s.priceSkeleton}>
                              <Skeleton
                                paragraph={{ rows: 1 }}
                                title={false}
                                width={"100%"}
                              />
                            </div>
                          ) : (
                            <div style={{ width: "100%" }}>
                              {isMonthly
                                ? pricingContents[mode.name].priceMonthly ===
                                  "Contact Us"
                                  ? "Contact Us"
                                  : pricingContents[mode.name].priceMonthly ===
                                    "FREE"
                                  ? "FREE"
                                  : `$${
                                      zohoPlansDetails?.find(
                                        (item) =>
                                          item.planCode ===
                                          mode.monthly_plan_code
                                      )?.recurringPrice
                                    }/mo`
                                : pricingContents[mode.name].priceAnnually ===
                                  "Contact Us"
                                ? "Contact Us"
                                : pricingContents[mode.name].priceAnnually ===
                                  "FREE"
                                ? "FREE"
                                : `$${
                                    zohoPlansDetails?.find(
                                      (item) =>
                                        item.planCode === mode.annual_plan_code
                                    )?.recurringPrice
                                  }/ann`}
                            </div>
                          )}
                          <MainButtonPrice
                            handleClick={() => handleSubscribe(mode)}
                            mode={mode}
                            currentPlan={currentPlan}
                            isAuthenticated={isAuthenticated}
                            pricingContents={pricingContents}
                            planCode={
                              isMonthly
                                ? mode.monthly_plan_code
                                : mode.annual_plan_code
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                  {pricingContents.subHeads.map((subHead) => (
                    <React.Fragment key={subHead.name}>
                      <tr key={subHead.name} className={s.pricingSubHead}>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          <b>{subHead.name}</b>
                        </td>
                      </tr>
                      {subHead.properties.map((property) => (
                        <tr key={property}>
                          <td>
                            <div className={s.priceHelpContainer}>
                              {property.title}
                              <span>
                                <Tooltip
                                  placement="top"
                                  title={property.description}
                                >
                                  <span>
                                    <a href="/" className={s.priceHelp}>
                                      {property.description && (
                                        <FontAwesomeIcon
                                          icon={["fas", "question-circle"]}
                                        />
                                      )}
                                    </a>
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </td>
                          {pricingContents.modes.map((mode) => {
                            return (
                              <td key={mode.name}>
                                {pricingContents[mode.name][subHead.name][
                                  property.title
                                ] === true ? (
                                  <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                                ) : pricingContents[mode.name][subHead.name][
                                    property.title
                                  ] === false ? (
                                  <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                                ) : (
                                  pricingContents[mode.name][subHead.name][
                                    property.title
                                  ]
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td key={mode.name} className={s.priceTableButton}>
                          <MainButtonPrice
                            handleClick={() => handleSubscribe(mode)}
                            mode={mode}
                            currentPlan={currentPlan}
                            isAuthenticated={isAuthenticated}
                            pricingContents={pricingContents}
                            planCode={
                              isMonthly
                                ? mode.monthly_plan_code
                                : mode.annual_plan_code
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </Spin>
    </section>
  );
}

export default HomepageContentsPricingSection;
