// Libs
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

// Styles
import "styles/index.scss";
import "styles/base.scss";

// icons
import initFontAwesome from "./utils/initFontAwesome";
import { CookiesProvider } from "react-cookie";
import GlobalContext from "context";
import MainRoutes from "routes";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "config";

initFontAwesome();

const queryClient = new QueryClient();
const config = getConfig();

const App = () => {
  return (
    <Auth0Provider
      clientId={config.clientId}
      domain={config.domain}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/profile`,
        scope: config.scope,
        audience: config.audience ? config.audience : null,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CookiesProvider>
            <GlobalContext>
              <MainRoutes />
            </GlobalContext>
          </CookiesProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default App;
