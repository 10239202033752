// Libs
import React from "react";

// Contents
import ErrorPageContents from "components/contents/main/error-page";

function ErrorPage() {
  return (
    <>
      <section>
        <ErrorPageContents />
      </section>
    </>
  );
}

export default ErrorPage;
