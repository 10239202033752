// Libs
import React, { useState } from "react";
import { Button, Card, Col, Row, Skeleton } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import ModalUpdateTimezone from "components/libs/modal/update-timezone";

// Hooks
import { useSDKUsers } from "hooks/user-sdk";

// Icons
import { ClockCircleOutlined, RightOutlined } from "@ant-design/icons";

// Styles
import s from "./index.module.scss";

function DashboardContentsProfileTimezoneSettings() {
  const [timezoneModal, setTimezoneModal] = useState(false);
  const { user } = useAuth0();

  //#region Handle User Details
  const {
    data: userDetails,
    onUpdate,
    loading,
  } = useSDKUsers({ externalUserId: user?.sub });
  //#endregion

  //#region Handle Timezone Modal
  const handleTimezoneModal = () => {
    setTimezoneModal(!timezoneModal);
  };
  //#endregion
  return (
    <>
      <Card title="Tenant Settings">
        <Row gutter={[16, 0]}>
          <Col className={s.iconContainer}>
            <ClockCircleOutlined />
          </Col>
          <Col className={s.settingContainer}>
            <h1>Time Zone</h1>
            <div className={s.descriptionContainer}>
              {loading && <Skeleton paragraph={1} />}
              {!loading && (
                <p className={s.description}>{userDetails?.timezone}</p>
              )}
            </div>
            <Button
              className={s.button}
              icon={<RightOutlined />}
              onClick={handleTimezoneModal}
            >
              Change Time Zone
            </Button>
          </Col>
        </Row>
      </Card>
      <ModalUpdateTimezone
        visible={timezoneModal}
        setVisible={handleTimezoneModal}
        userDetails={userDetails}
        onUpdate={onUpdate}
      />
    </>
  );
}

export default DashboardContentsProfileTimezoneSettings;
