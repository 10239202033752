// Libs
import { Spin } from "antd";
import propTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

// Styles
import s from "./index.module.scss";

// Icons
import { LoadingOutlined } from "@ant-design/icons";

function MainButton(props) {
  const { children, onClick, disabled, loading, customClass } = props;

  const loadingIcon = (
    <LoadingOutlined
      style={{
        fontSize: 18,
        color: "#eed151",
      }}
      spin
    />
  );

  return (
    <>
      <Button
        className={`${s.button} ${customClass}`}
        onClick={onClick}
        disabled={disabled || loading}
        {...props}
      >
        {children}{" "}
        {loading ? (
          <span style={{ marginLeft: 10, position: "relative", top: "-3px" }}>
            <Spin spinning={loading} indicator={loadingIcon} />
          </span>
        ) : (
          ""
        )}
      </Button>
    </>
  );
}

MainButton.propTypes = {
  children: propTypes.any,
  onClick: propTypes.func,
  disabled: propTypes.bool,
  customClass: propTypes.string,
};

export default MainButton;
