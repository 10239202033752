// Libs
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, Card, Col, Image, Row, Select } from "antd";

// Components
import MainButton from "components/libs/button";

// Hooks
import { useSDKUsers } from "hooks/user-sdk";

// Styles
import s from "./index.module.scss";
import { useSDKTenantUsers } from "hooks/tenant-user-sdk";
import { Link, useParams } from "react-router-dom";

function DashboardContentsProfileUserDetails() {
  const { user } = useAuth0();
  const { Option } = Select;
  const params = useParams();

  //#region User Hook
  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });
  const trialAvailable = userDetails?.trialAvailable;
  //#endregion

  //#region Tenant User Hook
  const { onSetDefaultTenant, loading: tenantUserLoading } = useSDKTenantUsers({
    tenantName: params.tenant,
  });
  //#endregion

  //#region Handle Tenant
  const [selectedTenant, setSelectedTenant] = useState(false);

  useEffect(() => {
    if (userDetails) {
      setSelectedTenant(
        userDetails?.tenants?.find((item) => item.defaultTenant)
      );
    }
  }, [userDetails]);
  //#endregion

  //#region Handle Change Default Tenant
  const handleChangeSelectedTenant = (value) => {
    const currentSelectedTenant = userDetails?.tenants?.find(
      (item) => item.tenantId === value
    );
    setSelectedTenant({
      id: currentSelectedTenant.tenantId,
      name: currentSelectedTenant.tenantName,
    });
  };

  const handleChangeDefaultTenant = async () => {
    const submission = {
      tenantName: selectedTenant.name,
    };
    const result = await onSetDefaultTenant(submission);
    if (result) {
      window.location.replace(`/dashboard/${selectedTenant.name}/profile`);
    }
  };
  //#endregion

  return (
    <>
      <Card title="User Details">
        <Row gutter={[32, 0]}>
          <Col span={4} className={s.imageContainer}>
            <Image
              src={user?.picture ? user?.picture : "/images/customer1.png"}
            />
          </Col>
          <Col span={20} className={s.userDetailsContainer}>
            <Row gutter={[16, 16]} justify="middle">
              <Col span={8}>
                <h3>Email:</h3>
                <p>{user?.email}</p>
              </Col>
              <Col span={8}>
                <h3>Name:</h3>
                <p>{user?.name}</p>
              </Col>
              <Col span={8}>
                <h3>Nick Name:</h3>
                <p>{user?.nickname}</p>
              </Col>
              <Col span={8}>
                <h3>User ID:</h3>
                <p>{user?.sub}</p>
              </Col>
              <Col span={8}>
                <h3>Default Tenant:</h3>
                {userDetails && (
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={
                      userDetails?.tenants?.find((item) => item.defaultTenant)
                        .tenantId
                    }
                    onChange={handleChangeSelectedTenant}
                  >
                    {userDetails?.tenants?.map((item) => {
                      return (
                        <>
                          <Option value={item.tenantId}>
                            {item.displayName}
                          </Option>
                        </>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Alert
                message={
                  trialAvailable > 0 ? (
                    <>
                      You have {`${trialAvailable}`} trial plan available for
                      your selected tenants!{" "}
                      <Link to={"../settings/subscription"}>Start Trial!</Link>
                    </>
                  ) : (
                    <>
                      No trial plans are available for your selected tenants.{" "}
                      <Link to={"../settings/subscription"}>
                        Subscribe Now!
                      </Link>
                    </>
                  )
                }
                type={trialAvailable > 0 ? "info" : "warning"}
                showIcon
              />
            </Row>
          </Col>
        </Row>

        <Col span={24} className={s.button}>
          <MainButton
            onClick={handleChangeDefaultTenant}
            loading={tenantUserLoading}
          >
            Save Changed
          </MainButton>
        </Col>
      </Card>
    </>
  );
}

export default DashboardContentsProfileUserDetails;
