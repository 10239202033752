import React from "react";
import propTypes from "prop-types";
import { Button } from "antd";

// Styles
import s from "./index.module.scss";

function MainButtonPrice(props) {
  const { handleClick, mode, currentPlan, isAuthenticated, planCode } = props;

  return (
    <>
      <Button
        className={`primary-button ${s.button}`}
        onClick={() => {
          handleClick(mode);
        }}
        disabled={currentPlan && currentPlan === planCode}
        hidden={planCode === "free"}
      >
        {!isAuthenticated
          ? "Sign Up"
          : currentPlan === planCode
          ? "Current Plan"
          : "Subscribe"}
      </Button>
    </>
  );
}

MainButtonPrice.propTypes = {
  handleClick: propTypes.func,
  mode: propTypes.object,
  currentPlan: propTypes.string,
  isAuthenticated: propTypes.bool,
  planCode: propTypes.string,
};

export default MainButtonPrice;
