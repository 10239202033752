import s from "./index.module.scss";
import propTypes from "prop-types";

export default function CompLibsCredentialsLocationTitle(props) {
  const { context, type = "gcp" } = props;

  const imgType = {
    AWS: "/aws-logo.png",
    AZURE: "/azure-logo.png",
    GCP: "/gcloud-logo.png",
  };

  return (
    <div>
      <h1 className={s.tableTitle}>
        <span className={s.tableLogo}>
          <img src={imgType[type.toUpperCase()]} alt="" />
        </span>
        {type.toUpperCase()} {context}
      </h1>
    </div>
  );
}

CompLibsCredentialsLocationTitle.propTypes = {
  context: propTypes.string,
  type: propTypes.oneOf(["gcp", "aws", "azure"]),
};
