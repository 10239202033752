// Libs
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";

// Components
import MainButton from "components/libs/button";

// Style
import s from "./index.module.scss";

// Icons
import { DeleteFilled } from "@ant-design/icons";
import { useSDKUsers } from "hooks/user-sdk";
import { useCookies } from "react-cookie";

function AfterDeleteAccount() {
  const navigate = useNavigate();
  const { 2: removeCookie } = useCookies();

  //#region User Hooks
  const { onDelete } = useSDKUsers({ externalUserId: "" });
  //#endregion

  //#region Handle Delete
  const handleDelete = async (userId) => {
    await onDelete(userId);
  };

  useEffect(() => {
    const userId = window.localStorage.getItem("userId");
    handleDelete(userId);
    removeCookie("access_token");
    window.localStorage.removeItem("userId");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  return (
    <>
      <Result
        icon={<DeleteFilled className={s.icon} />}
        title="Account deleted successfully"
        subTitle="Your account already logout automatically"
        extra={[
          <MainButton
            type="primary"
            key="console"
            onClick={() => navigate("/")}
          >
            Back to Homepage
          </MainButton>,
        ]}
      />
    </>
  );
}

export default AfterDeleteAccount;
