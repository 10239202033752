import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import MainLoading from "components/libs/loading";
import { useSDKTenants } from "hooks/tenants-sdk";
import { useSDKUsers } from "hooks/user-sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SubscriptionRedirectionPage() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  //#region User SDK
  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });
  const defaultTenant = userDetails?.tenants?.find(
    (item) => item.defaultTenant
  );
  //#endregion

  //#region Tenant SDK
  const { data: tenantDetails } = useSDKTenants({
    tenantName: defaultTenant?.tenantName,
  });
  //#endregion

  //#region Handle Redirection
  useEffect(() => {
    if (!!tenantDetails) {
      if (tenantDetails.plan !== "free" && tenantDetails.plan) {
        navigate(
          `/dashboard/${defaultTenant?.tenantName}/checkout?menu=addons`
        );
      } else {
        navigate(
          `/dashboard/${defaultTenant?.tenantName}/settings/subscription`
        );
      }
    }
  }, [defaultTenant?.tenantName, navigate, tenantDetails]);
  //#endregion

  return (
    <>
      <MainLoading />
    </>
  );
}

export default withAuthenticationRequired(SubscriptionRedirectionPage, {
  onRedirecting: () => <MainLoading />,
  returnTo: "/",
});
