import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import s from "./index.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSDKCredentials } from "hooks/credentials-sdk";
import { useAuth0 } from "@auth0/auth0-react";
import useQueryString from "hooks/useQueryString";

export default function RetryAddCredentialContent() {
  const [duration, setDuration] = useState(5);
  const navigate = useNavigate();
  const param = useParams();
  const { tenant: tenantName } = param;
  const { user } = useAuth0();
  const query = useQueryString();
  const retry = query.get("retry");

  //#region  Handle Add Credentials
  const { onAddGcp } = useSDKCredentials({ tenantName: tenantName });
  const handleAddGcp = () => {
    onAddGcp({
      redirectUri: `${window.location.origin}/dashboard/${tenantName}/credentials`,
      errorRedirectUri: `${
        window.location.origin
      }/dashboard/${tenantName}/retry-add-credential?retry=${+retry + 1}`,
      email: user?.email,
      tenantName: tenantName,
    });
  };
  //#endregion

  //#region  Handle redirect to many redirect page
  useEffect(() => {
    if (retry > 3) {
      navigate(`/dashboard/${tenantName}/too-many-request`, {
        description: `<div>
            We have observed that you have made multiple attempts to create credentials. 
            Please note that our system has a limit of 3 retries for credential creation. 
            If you encounter any difficulties, please feel free to <a>contact us</a>, and we will be glad to assist you. 
            Thank you for your understanding.
            </div>`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, tenantName]);
  //#endregion

  //#region  Handle duration
  useEffect(() => {
    const handleDuration = setTimeout(() => {
      if (duration > 0) {
        setDuration(duration - 1);
      } else {
        handleAddGcp();
      }
    }, 1000);

    return () => {
      clearTimeout(handleDuration);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);
  //#endregion

  return (
    <>
      <Card>
        <section className={s.section}>
          <div style={{ width: "100%" }}>
            <Col span={24}>
              <img src="/warning.png" alt="warning-icon" className={s.image} />
            </Col>
            <Col span={10} style={{ margin: "15px auto 0" }}>
              <p>
                We encountered an issue while adding your credentials. <br />
                Please try again. You will be automatically redirected to the
                Google page in {duration} seconds.
              </p>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 0]} justify={"center"} align={"middle"}>
                <Col>
                  <Button type="primary" onClick={handleAddGcp}>
                    Try Again ({duration}s)
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      navigate(`/dashboard/${tenantName}/credentials`);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </section>
      </Card>
    </>
  );
}
