import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";
import NotificationWarningExpiredCredentials from "components/libs/notifications/expired-credentials";
import { usePlanSuggestion } from "context/plan-suggestion";
import NotificationErrorRemoveCredentials from "components/libs/notifications/remove-credentials";

export const useSDKCredentials = ({ limit, page, tenantName, type = "" }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const navigate = useNavigate();

  const { setOpenModal, setTopic, setTenantName } = usePlanSuggestion();

  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["credentials", type],
    queryFn: async () => {
      const data = await apiSDK.tenantApi.getCredentialsByTenantName({
        tenantName: `${tenantName}`,
        type: type,
      });
      return data;
    },
    onError: () => {
      navigate(`/dashboard/${tenant}/error-page`);
    },
  });

  //#region  Add Credentials by GCP
  const { mutate: onAddGcp } = useMutation(
    useCallback(async (payload) => {
      const res = await apiSDK.credentialsApi.addGcpCredential({
        v1GcpCredentialForm: {
          redirectUri: payload.redirectUri,
          email: payload.email,
          tenantName: payload.tenantName,
        },
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        window.location.replace(res.result.gcpUrl);
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Credential");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }

        return false;
      },
    }
  );
  //#endregion

  //#region  Add Credentials by AWS
  const { mutateAsync: onAddAws, isLoading: onAddAwsLoading } = useMutation(
    useCallback(
      async (data) => {
        try {
          const res = await apiSDK.credentialsApi.addAwsCredential({
            v1AwsCredentialForm: {
              accessKey: data?.accessKey || null,
              cloudId: data?.cloudId || null,
              fileUpload: data?.fileUpload || null,
              secretKey: data?.secretKey || null,
              tenantName: tenantName,
              region: data?.region || null,
            },
          });
          return res;
        } catch (error) {
          notification.warning({
            message: "Something went wrong!",
            description: "Credentials is created but Invalid!",
          });
        }
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["credentials"]);
        if (res) {
          notification.success({
            message: "Successfully",
            description: "successfully add new credentials",
          });
        }
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Credential");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //#region  Add Credentials by Azure
  const { mutateAsync: onAddAzure, isLoading: onAddAzureLoading } = useMutation(
    useCallback(
      async (data) => {
        const res = await apiSDK.credentialsApi.addAzureCredential({
          v1AzureCredentialForm: {
            azureSubscriptionId: data?.azureSubscriptionId || null,
            azureTenantId: data?.azureTenantId || null,
            clientId: data?.clientId || null,
            clientSecret: data?.clientSecret || null,
            tenantName: tenantName,
          },
        });
        return res;
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["credentials"]);
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "QUOTA_LIMIT_EXCEEDED") {
          setOpenModal(true);
          setTopic("Credential");
          setTenantName(tenant);
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //#region  Update GCP Credentials
  const { mutate: onUpdateGcp } = useMutation(
    useCallback(
      async ({ id, submission }) => {
        const res = await apiSDK.credentialsApi.updateGcpCredential({
          credentialId: id,
          v1GcpCredentialForm: {
            cloudId: submission.cloudId,
            redirectUri: submission.redirectUri,
            tenantName: tenantName,
          },
        });
        return res;
      },
      [tenantName]
    ),
    {
      onSuccess: (res) => {
        window.location.replace(res.result.gcpUrl);
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region  Update AWS Credentials
  const { mutateAsync: onUpdateAws, isLoading: onUpdateAwsLoading } =
    useMutation(
      useCallback(async ({ id, submission }) => {
        const res = await apiSDK.credentialsApi.updateAwsCredential({
          credentialId: id,
          v1AwsCredentialForm: {
            accessKey: submission?.accessKey || null,
            cloudId: submission?.cloudId || null,
            fileUpload: submission?.fileUpload || null,
            secretKey: submission?.secretKey || null,
            tenantName: submission.tenantName,
            region: submission?.region,
          },
        });
        return res;
      }, []),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(["credentials"]);
          notification.success({
            message: "Successfully",
            description: "successfully update credentials",
          });
          return true;
        },
        onError: async (err) => {
          const errorResponse = await err.response.json();
          NotificationErrorSomethingWrong(errorResponse?.detail);
          return false;
        },
      }
    );
  //#endregion

  //#region  Update Azure Credentials
  const { mutateAsync: onUpdateAzure, isLoading: onUpdateAzureLoading } =
    useMutation(
      useCallback(
        async ({ id, submission }) => {
          const res = await apiSDK.credentialsApi.updateAzureCredential({
            credentialId: id,
            v1AzureCredentialForm: {
              azureSubscriptionId: submission?.azureSubscriptionId || null,
              azureTenantId: submission?.azureTenantId || null,
              clientId: submission?.clientId || null,
              clientSecret: submission?.clientSecret || null,
              tenantName: tenantName,
            },
          });
          return res;
        },
        [tenantName]
      ),
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries(["credentials"]);
          notification.success({
            message: "Successfully",
            description: "successfully update credentials",
          });
          return res;
        },
        onError: async (err) => {
          const errorResponse = await err.response.json();
          NotificationErrorSomethingWrong(errorResponse?.detail);
        },
      }
    );
  //#endregion

  //#region  Delete Credentials
  const { mutateAsync: onDelete, isLoading: onDeleteLoading } = useMutation(
    useCallback(async (id) => {
      const res = await apiSDK.credentialsApi.revokeCredential({
        credentialId: id,
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        notification.success({
          message: "Successfully",
          description: "successfully delete selected credentials",
        });

        queryClient.invalidateQueries(["credentials"]);
        return true;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        if (errorResponse.error_code === "CREDENTIAL_FIREWALL_DEPENDENT") {
          const cloudId = data?.result?.[0]?.cloudId;
          NotificationErrorRemoveCredentials({
            onClick: () => {
              navigate(
                `/dashboard/${tenant}/firewalls?cloudId=${encodeURIComponent(
                  cloudId
                )}`
              );
            },
          });
        } else {
          NotificationErrorSomethingWrong(errorResponse?.detail);
        }
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    onAddGcp,
    onAddAws,
    onAddAzure,
    onUpdateGcp,
    onUpdateAws,
    onUpdateAzure,
    onDelete,
    loading:
      isFetching ||
      onDeleteLoading ||
      onAddAwsLoading ||
      onUpdateAwsLoading ||
      onAddAzureLoading ||
      onUpdateAzureLoading,
    error: error,
  };
};

export const useSDKCredentialsGcpInfo = ({ credentials }) => {
  const navigate = useNavigate();
  const params = useParams();
  const tenantName = params.tenant;

  const { data, isFetching } = useAuthQuery({
    queryKey: ["gcp-info", credentials?.credentialId],
    queryFn: async () => {
      const data = await apiSDK.credentialsApi.getGcpInfoByCredentialId({
        credentialId: credentials?.credentialId,
      });
      return data;
    },
    enabled: !!credentials,
    onError: (err) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      NotificationWarningExpiredCredentials({ onClick: handleRedirect });
    },
    onSuccess: (res) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      if (tenantName.includes("<") && credentials.status !== "VALID") {
        NotificationWarningExpiredCredentials({ onClick: handleRedirect });
      }
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};

export const useSDKCredentialsAzureInfo = ({ credentials }) => {
  const navigate = useNavigate();
  const params = useParams();
  const tenantName = params.tenant;

  const { data, isFetching } = useAuthQuery({
    queryKey: ["azure-info", credentials?.credentialId],
    queryFn: async () => {
      const data = await apiSDK.credentialsApi.getAzureInfoByCredentialId({
        credentialId: credentials?.credentialId,
      });
      return data;
    },
    enabled: !!credentials,
    onError: (err) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      NotificationWarningExpiredCredentials({ onClick: handleRedirect });
    },
    onSuccess: (res) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      if (tenantName.includes("<") && credentials.status !== "VALID") {
        NotificationWarningExpiredCredentials({ onClick: handleRedirect });
      }
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};

export const useSDKCredentialsAwsInfo = ({ credentials }) => {
  const navigate = useNavigate();
  const params = useParams();
  const tenantName = params.tenant;

  const { data, isFetching } = useAuthQuery({
    queryKey: ["aws-info", credentials?.credentialId],
    queryFn: async () => {
      const data = await apiSDK.credentialsApi.getAwsInfoByCredentialId({
        credentialId: credentials?.credentialId,
      });
      return data;
    },
    enabled: !!credentials,
    onError: (err) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      NotificationWarningExpiredCredentials({ onClick: handleRedirect });
    },
    onSuccess: (res) => {
      const handleRedirect = () => {
        navigate(
          `/dashboard/${tenantName}/credentials?mode=edit&credId=${credentials?.credentialId}`
        );
      };
      if (tenantName.includes("<") && credentials.status !== "VALID") {
        NotificationWarningExpiredCredentials({ onClick: handleRedirect });
      }
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};

export const useSDKCredentialsAwsRegion = () => {
  const { data, isFetching } = useAuthQuery({
    queryKey: ["aws-region"],
    queryFn: async () => {
      const data = await apiSDK.credentialsApi.getAwsRegionInfo();
      return data;
    },
    onError: (err) => {
      console.log(err);
    },
    onSuccess: (res) => {
      return res;
    },
  });

  const result = data?.regions || [];

  return {
    data: result,
    loading: isFetching,
  };
};
